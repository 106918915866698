import { makeStyles } from '@material-ui/core/styles';
import { LoadingContainer } from 'components/LoadingContainer';
import { Searchbar } from 'components/SearchBar';
import { Column, Table } from 'components/Table';
import { ApplicationWithAgentDTO, SecurityScanDTO, TargetDTO, TargetType } from 'dtos/application';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import { useSecurityFindingsByDomain } from 'queries/useSecurityFinding';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createSecurityRoute, generateRefetchIntervalMs } from 'telivy-constants';
import { mapScanningStatus, mapTargetType } from 'telivy-maps';
import { CreateTargetModal } from 'templates/SecurityAssessments/components/CreateTargetModal';
import { RescanButton } from 'templates/SecurityAssessments/components/RescanButton';

import { SourceRoute } from '../../assets';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.25),
  },
  actionButton: {
    marginRight: theme.spacing(2),
  },
}));

const columns: Array<Column<TargetDTO, keyof TargetDTO>> = [
  {
    title: 'Domain/Address',
    width: '35%',
    render: (target) => target.address,
  },
  {
    title: 'Type',
    width: '20%',
    render: (target) => mapTargetType(target.type),
  },
  {
    title: 'Scanning Status',
    width: '25%',
    render: (target) => mapScanningStatus(target.scanningStatus),
  },
  {
    title: 'Findings',
    width: '20%',
    render: (target) => target.numberOfFindings,
  },
];

interface Props {
  securityScan: SecurityScanDTO;
  applicationId: string;
  application?: ApplicationWithAgentDTO;
  sourceRoute?: SourceRoute;
  securityScanId: string;
  externalScanDisabled?: boolean;
}

export const Domains = ({
  application,
  applicationId,
  sourceRoute,
  securityScan,
  securityScanId,
  externalScanDisabled,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 200);
  const { data, isLoading } = useSecurityFindingsByDomain(
    {
      scanId: securityScan.id,
    },
    {
      refetchInterval: generateRefetchIntervalMs(),
    },
  );

  const targets: TargetDTO[] = useMemo(() => {
    const domainItem = {
      type: TargetType.DOMAIN,
      address: securityScan.domain,
      scanningStatus: securityScan.status,
      numberOfFindings: data?.find((f) => f.domain === securityScan.domain)?.count || 0,
    };

    const items: TargetDTO[] = securityScan.domain !== 'nodomain.local' ? [domainItem] : [];

    // if ((securityScan.subDomainScans || []).length > 0 && false) {
    //   for (const scan of securityScan.subDomainScans || []) {
    //     items.push({
    //       type: scan.targetType,
    //       address: scan.domain,
    //       scanningStatus: scan.status,
    //       numberOfFindings: data?.find((f) => f.domain === scan.domain)?.count || 0,
    //     });
    //   }
    // } else {
    const excludedTargets = securityScan.excludedTargets || [];

    for (const otherDomain of securityScan.otherDomains || []) {
      if (excludedTargets.indexOf(otherDomain) > -1) continue;

      items.push({
        type: TargetType.DOMAIN_SUB_DOMAIN,
        address: otherDomain,
        scanningStatus: securityScan.status,
        numberOfFindings: 0,
      });
    }

    for (const ipAddress of securityScan.ipAddresses || []) {
      if (excludedTargets.indexOf(ipAddress) > -1) continue;

      items.push({
        type: TargetType.IP_ADDRESS,
        address: ipAddress,
        scanningStatus: securityScan.status,
        numberOfFindings: 0,
      });
    }
    // }

    return items;
  }, [data, securityScan]);

  // const targets: TargetDTO[] = useMemo(() => {

  //   // if ((securityScan.subDomainScans || []).length > 0) {
  //   //   for (const scan of securityScan.subDomainScans || []) {
  //   //     items.push({
  //   //       type: securityScan.targetType,
  //   //       address: scan.domain,
  //   //       scanningStatus: securityScan.status,
  //   //       numberOfFindings: data?.find((f) => f.domain === scan.domain)?.count || 0,
  //   //     });
  //   //   }
  //   // } else {
  //   const excludedTargets = securityScan.excludedTargets || [];

  //   for (const otherDomain of securityScan.otherDomains || []) {
  //     if (excludedTargets.indexOf(otherDomain) > -1) continue;

  //     items.push({
  //       type: TargetType.DOMAIN_SUB_DOMAIN,
  //       address: otherDomain,
  //       scanningStatus: securityScan.status,
  //       numberOfFindings: 0,
  //     });
  //   }

  //   for (const ipAddress of securityScan.ipAddresses || []) {
  //     if (excludedTargets.indexOf(ipAddress) > -1) continue;

  //     items.push({
  //       type: TargetType.IP_ADDRESS,
  //       address: ipAddress,
  //       scanningStatus: securityScan.status,
  //       numberOfFindings: 0,
  //     });
  //   }
  //   // }

  //   return items;
  // }, [data, securityScan]);

  const handleClick = (target: TargetDTO) => {
    if (sourceRoute) {
      navigate(
        createSecurityRoute(
          `${sourceRoute.ROOT}/${sourceRoute.security.ROOT}/${sourceRoute.security.FINDINGS}`,
          applicationId,
          securityScanId,
        ) + `?target=${target.address}`,
      );
    }
  };

  const sortedFilteredTargets = useMemo(() => {
    const filteredTargets = targets.filter((target) =>
      target.address.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
    );
    return filteredTargets.sort((a, b) => b.numberOfFindings - a.numberOfFindings);
  }, [debouncedSearchValue, targets]);

  if (isLoading) {
    return <LoadingContainer />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.actionsWrapper}>
        <div className={classes.filters}>
          <Searchbar placeholder='Search targets...' value={searchValue} setValue={setSearchValue} />
        </div>
        <div>
          {application && (
            <RescanButton
              applicationId={applicationId}
              securityScanId={'latest'}
              isScanning={false}
              className={classes.actionButton}
            />
          )}
          {application && (
            <CreateTargetModal
              application={application}
              className={classes.actionButton}
              locked={externalScanDisabled}
            />
          )}
          {/*application && securityScan && !externalScanDisabled && (
            <RemoveTargetsModal application={application} securityScan={securityScan} />
          )*/}
        </div>
      </div>
      <Table<TargetDTO>
        columns={columns}
        loading={isLoading}
        data={sortedFilteredTargets}
        onRowClick={(target) => (target.numberOfFindings ? handleClick(target) : undefined)}
        rowKey={(el) => el.address}
      />
    </div>
  );
};
