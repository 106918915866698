import { makeStyles, Theme } from '@material-ui/core';
// import cx from 'classnames';
import { LoadingContainer } from 'components/LoadingContainer';
import { SingleFilter } from 'components/SingleFilter';
import { ApplicationWithAgentDTO, MonitoringSnapshotDTO, SecurityScanDTO } from 'dtos/application';
import { SecurityFindingBySlugDTO, SecurityFindingSlug, SecurityScanType } from 'dtos/security-finding';
import { useMonitoringDarkWebPresenceChart } from 'hooks/charts/monitoring/useDarkWebPresenceChart';
import { useExternalScanFindings } from 'hooks/external-scan/useExternalScanFindings';
import { useExternalScanPreviousFindings } from 'hooks/external-scan/useExternalScanPreviousFindings';
import uniq from 'lodash/uniq';
import { useApplicationBreachData } from 'queries/useApplications';
import { useDeepScanReportData } from 'queries/useDeepScan';
import React, { useMemo } from 'react';
import { createSecurityRoute } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { LIMITS } from 'views/agent/views/application-details/views/monitoring/RiskAssessmentHistory';

import { SourceRoute } from '../..';
import { ScoreSecurityCard } from '../../components/common/ScoreSecurityCard';
import { DarkWebPresenceChart } from '../../components/DarkWebPresenceChart';
import { renderScanResults } from '../../components/ExternalScanItem';
import { SecurityDarkWeb } from '../SecurityDarkWeb';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(1),
  },

  findings: {
    color: COLORS.GREY_3,
  },

  section: {
    marginBottom: theme.spacing(4),
  },

  summary: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(2),
  },

  item: {
    border: `solid 1px ${COLORS.GREY_5}`,
    backgroundColor: 'rgba(249, 251, 252, 0.5)',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  description: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_2,
    marginTop: theme.spacing(1),
  },

  number: {
    ...TYPOGRAPHY.TITLE_2,
    fontSize: 48,
    color: COLORS.TEXT,
  },

  red: {
    color: COLORS.RED_1,
  },

  scanResultsWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(1.25),
    },
  },

  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  boxBorder: {
    border: 'solid 1px #efefef',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  lineChart: {
    height: 400,
  },
  redItalic: {
    color: COLORS.RED_1,
    fontStyle: 'italic',
  },
  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  securityCard: {
    flex: 1,
  },
}));

interface Props {
  securityScan: SecurityScanDTO;
  applicationId: string;
  enableDarkWebFullPassword: boolean;
  sourceRoute: SourceRoute;
  securityScanId: string;
  isAdmin?: boolean;
  application: ApplicationWithAgentDTO;
  monitoringSnapshots?: MonitoringSnapshotDTO[];
  selectedAccounts: string[];
  setSelectedAccounts: (a: string[]) => void;
  allAccounts: string[];
  selectedLimit: string;
  setSelectedLimit: (a: string) => void;
}

export const DeepScanSecurityAwarenessTraining = ({
  applicationId,
  enableDarkWebFullPassword,
  sourceRoute,
  securityScan,
  securityScanId,
  application,
  monitoringSnapshots,
  selectedAccounts,
  setSelectedAccounts,
  allAccounts,
  selectedLimit,
  setSelectedLimit,
}: Props) => {
  const classes = useStyles();

  const { findingsByType, isErrorScanEmail, isLoadingScanEmail } = useExternalScanFindings({ securityScan });

  const { previousFindingsByType } = useExternalScanPreviousFindings({ securityScan });

  const getFindingUrl = (finding: SecurityFindingBySlugDTO) =>
    createSecurityRoute(sourceRoute.security.FINDING, applicationId, securityScanId, finding.slug);

  const { data: breachData, isLoading: isBreachDataLoading } = useApplicationBreachData(applicationId);

  const { chart: darkWebPresenceChart } = useMonitoringDarkWebPresenceChart(
    application,
    application.monitoringEnabled,
    monitoringSnapshots,
    selectedAccounts,
    classes.lineChart,
  );

  const emailsBreached = useMemo(() => {
    return uniq((breachData || []).map((a) => a.email));
  }, [breachData]);

  const { data: reportData } = useDeepScanReportData(application.deepScanId || '', {
    enabled: Boolean(application.deepScanId),
  });

  const isLoadingAny = !securityScan || isBreachDataLoading;

  if (isLoadingAny) {
    return <LoadingContainer />;
  }

  const breachesCount = findingsByType[SecurityScanType.HACKER_CHATTER].filter((f) => f.count > 0).length;

  return (
    <div className={classes.root}>
      <div className={classes.title}>Dark Web Presence</div>

      <div className={classes.section}>
        <div className={classes.summary} id='summary'>
          <ScoreSecurityCard
            className={classes.securityCard}
            title='Total Data Found'
            skipGrading={(breachData || []).length.toLocaleString()}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title='Total Emails Breached'
            skipGrading={(emailsBreached || []).length.toLocaleString()}
            score={emailsBreached.length > 0 ? 50 : (reportData?.securityAwarenessTraining.score || 0) * 100}
          />
          {/*<div className={classes.item}>
            <div className={classes.number}>{(breachData || []).length.toLocaleString()}</div>
            <div className={classes.description}>Total Data Found</div>
          </div>
          <div className={classes.item}>
            <div className={cx(classes.number, classes.red)}>
              {uniq((breachData || []).map((a) => a.email)).length.toLocaleString()}
            </div>
            <div className={classes.description}>Total Emails Breached</div>
          </div>*/}
        </div>
      </div>

      <div className={classes.section}>
        {breachesCount > 0 && (
          <DarkWebPresenceChart
            securityScan={securityScan}
            findingsUrl={createSecurityRoute(
              `${sourceRoute.ROOT}/${sourceRoute.security.ROOT}/${sourceRoute.security.FINDINGS}`,
              applicationId,
              'latest',
              '',
            )}
            applicationId={applicationId}
          />
        )}
      </div>

      <div className={classes.section}>
        <div className={classes.filterBar}>
          <div className={classes.title}>
            Trends
            <br />
            <span className={classes.redItalic}> Number of Dark Web Findings</span>
          </div>
          <div>
            <SingleFilter
              buttonText={`Accounts ${selectedAccounts.length > 0 ? `(${selectedAccounts.length})` : ''}`}
              defaultSelectedOptions={selectedAccounts}
              popperText='Filter Accounts'
              showDropdownIcon
              options={allAccounts}
              setSelectedOptions={(a) => {
                setSelectedAccounts(a);
              }}
            />
            <SingleFilter
              type='radio'
              buttonText={selectedLimit}
              defaultSelectedOptions={[selectedLimit]}
              popperText='Select Period'
              showDropdownIcon
              options={Object.keys(LIMITS)}
              setSelectedOptions={(a) => {
                setSelectedLimit(a[0]);
              }}
              startIcon={<></>}
              className={classes.marginLeft}
            />
          </div>
        </div>
        <div className={classes.chartContainer}>{darkWebPresenceChart}</div>
      </div>

      <div className={classes.section}>
        <SecurityDarkWeb
          applicationId={applicationId}
          enableDarkWebFullPassword={enableDarkWebFullPassword}
          hideWhenEmpty
        />
      </div>

      {/*<div className={classes.section}>
        <div className={classes.title}>
          Breaches
          <span className={classes.findings}>
            {' '}
            ({findingsByType[SecurityScanType.HACKER_CHATTER].filter((f) => f.count > 0).length} findings)
            {isLoadingScanEmail ? '(External scan ongoing)' : ''}
          </span>
        </div>
        <div className={classes.boxBorder}>
          {(!isErrorScanEmail || securityScan.previousScan?.emailScannedAt) && (
            <div className={classes.scanResultsWrapper}>
              {renderScanResults(
                findingsByType[SecurityScanType.HACKER_CHATTER] || [],
                getFindingUrl,
                true,
                false,
                previousFindingsByType[SecurityScanType.HACKER_CHATTER] || undefined,
              )}
            </div>
          )}
        </div>
      </div>*/}

      <div className={classes.section}>
        <div className={classes.title}>
          Social Engineering
          <span className={classes.findings}>
            {' '}
            (
            {
              findingsByType[SecurityScanType.SOCIAL_ENGINEERING].filter(
                (f) => f.count > 0 && f.slug != SecurityFindingSlug.NO_EMPLOYEE_TRAINING,
              ).length
            }{' '}
            findings)
            {isLoadingScanEmail ? '(External scan ongoing)' : ''}
          </span>
        </div>
        <div className={classes.boxBorder}>
          {(!isErrorScanEmail || securityScan.previousScan?.emailScannedAt) && (
            <div className={classes.scanResultsWrapper}>
              {renderScanResults(
                (findingsByType[SecurityScanType.SOCIAL_ENGINEERING] || []).filter(
                  (f) => f.slug != SecurityFindingSlug.NO_EMPLOYEE_TRAINING,
                ),
                getFindingUrl,
                true,
                false,
                previousFindingsByType[SecurityScanType.SOCIAL_ENGINEERING] || undefined,
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
