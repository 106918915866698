import { AgentDTO } from 'dtos/agent';
import { ReactComponent as SaluswallLogo } from 'images/saluswall-logo.svg';
import { ReactComponent as TelivyLogo } from 'images/telivy-logo.svg';
import React from 'react';
import { isUrlSaluswall } from 'telivy-selectors';

interface Props {
  className?: string;
  agent?: AgentDTO;
}

export const CompanyLogo = ({ className, agent }: Props) => {
  const masterLogo = agent?.masterLogo || (agent?.agency?.isMasterAgency && agent?.agency?.logoUrl);

  if (masterLogo) {
    return <img src={masterLogo} alt='master-logo' className={className} />;
  }

  if (isUrlSaluswall) {
    return <SaluswallLogo className={className} />;
  }

  return <TelivyLogo className={className} />;
};
