import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { SortOrder } from 'components/Table';
import {
  ApplicationInsuranceLineQueryOptions,
  ApplicationOrigin,
  ApplicationSortBy,
  ApplicationStatus,
} from 'dtos/application';
import { ReactComponent as CloseIcon } from 'images/close.svg';
import { ReactComponent as SearchIcon } from 'images/search.svg';
import { pickBy } from 'lodash';
import intersection from 'lodash/intersection';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { NewFilterButton } from 'views/agent/views/dashboard/NewFilterButton';

import { BulkArchivedButton } from './BulkArchivedButton';
import { BulkReassignButton } from './BulkReassignButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',

    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(),
  },
  itemsContainer: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1.5),
  },
  title: {
    ...TYPOGRAPHY.LARGE,
    margin: 'auto 15px auto 0',
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },

  inputContainer: {
    position: 'relative',
    display: 'flex',
  },
  searchIcon: {
    position: 'absolute',
    left: theme.spacing(2),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  searchInput: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    border: `1px solid ${COLORS.GREY_4}`,
    background: COLORS.WHITE,
    borderRadius: theme.spacing(1),
    width: 270,
    '&:focus': {
      borderColor: COLORS.BLUE_1,
    },
    '&:focus-visible': {
      outline: 'none',
    },
    '&::placeholder': {
      color: COLORS.GREY_3,
    },
  },
  filtersContainer: {
    position: 'relative',
    display: 'flex',
    gap: theme.spacing(1.5),
    flexWrap: 'wrap',
  },
  clearIcon: {
    display: 'flex',
    border: 'none',
    margin: 0,
    position: 'absolute',
    right: theme.spacing(2),
    top: '50%',
    background: 'none',
    transform: 'translateY(-50%)',
  },
  monitoringFormContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
  },
  monitoringForm: {
    alignSelf: 'end',
    marginLeft: theme.spacing(1),
  },
}));

export interface FilterParams {
  status?: ApplicationStatus | null;
  origin?: ApplicationOrigin | null;
  showBulk?: boolean;
  showMonitored?: boolean;
  effectiveDateDays?: number;
  creationDateDays?: number;
}

export interface QueryParams extends FilterParams {
  q?: string;
  page?: number;
  sortBy?: ApplicationSortBy;
  sortOrder?: SortOrder;
  insuranceLine?: ApplicationInsuranceLineQueryOptions;
  agencyId?: string;
  agentId?: string;
}

export type AvailableFilter<T> = {
  [Property in keyof T]: {
    label: string;
    icon?: JSX.Element;
    options: Array<{
      label: string;
      value: T[Property];
    }>;
  };
};

interface Props<Params> {
  title: string;
  className?: string;
  onFilterChange: (paramsToUpdate: Partial<Params>) => void;
  currentFilters: Params;
  filterList: AvailableFilter<Params>;
  applicationIds: string[];
  onBulkAction?: () => void;
}

export function Filters({
  title,
  className,
  currentFilters,
  onFilterChange,
  filterList,
  applicationIds,
  onBulkAction,
}: Props<QueryParams>) {
  const classes = useStyles();

  // currentFilter without falsy values
  const currentFiltersReduced = pickBy(currentFilters);
  // all filter names as an array of strings
  const filterListKeys = Object.keys(filterList) as Array<keyof AvailableFilter<QueryParams>>;
  // currentFilters names as an array of strings
  const filterKeys = intersection(
    Object.keys(currentFiltersReduced) as Array<keyof Partial<QueryParams>>,
    filterListKeys,
  );
  // currentFilters as an array of objects
  const filtersFormatted = filterKeys.map((filter) => ({
    name: filter,
    value: currentFiltersReduced[filter] || undefined,
  }));

  // not yet used filters - filters available to add
  const availableFilters = filterListKeys.filter((filter) => !filterKeys.includes(filter));

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.itemsContainer}>
        <Typography variant='h2' className={classes.title}>
          {title}
        </Typography>
        <div className={classes.inputContainer}>
          <SearchIcon className={classes.searchIcon} />
          <input
            placeholder='Search company name...'
            value={currentFilters.q}
            onChange={(e) => onFilterChange({ q: e.target.value })}
            className={classes.searchInput}
          />
          {currentFilters.q && currentFilters.q.length > 0 && (
            <button className={classes.clearIcon} onClick={() => onFilterChange({ q: '' })}>
              <CloseIcon />
            </button>
          )}
        </div>
        <div className={classes.filtersContainer}>
          {(filtersFormatted || []).map((filter, idx: number) => (
            <NewFilterButton<QueryParams>
              key={idx}
              currentFilter={filter}
              onFilterChange={(paramsToUpdate) => onFilterChange(paramsToUpdate)}
              filterList={filterList}
            />
          ))}
          {filtersFormatted?.length < filterListKeys.length && (
            <NewFilterButton<QueryParams>
              currentFilter={{ name: undefined, value: undefined }}
              onFilterChange={(paramsToUpdate) => onFilterChange(paramsToUpdate)}
              availableFilters={availableFilters}
              filterList={filterList}
            />
          )}
        </div>
        <div className={classes.monitoringFormContainer}>
          <BulkReassignButton
            applicationIds={applicationIds}
            className={classes.monitoringForm}
            onBulkAction={onBulkAction}
          />
          <BulkArchivedButton
            applicationIds={applicationIds}
            className={classes.monitoringForm}
            onBulkAction={onBulkAction}
          />
        </div>
      </div>
    </div>
  );
}
