import { makeStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { EmptyState } from 'components/EmptyState';
import { LoadingContainer } from 'components/LoadingContainer';
import { Option, RadioSelect } from 'components/RadioSelect';
import { ApplicationWithAgentDTO, SecurityScanStatus } from 'dtos/application';
import { DataRiskDistributionDTO } from 'dtos/deep-scan';
// import { useChartsEnabled } from 'hooks/useChartsEnabled';
import { uniq } from 'lodash';
import uniqBy from 'lodash/uniqBy';
import { useCurrentAgent } from 'queries/useAgent';
import { useApplicationSecurityScan } from 'queries/useApplications';
import {
  useDeepScan,
  useDeepScanDataRiskDistribution,
  useDeepScanInstalledApplications,
  useDeepScanNetworkHosts,
  useDeepScanParsedReports,
  useDeepScanTargets,
} from 'queries/useDeepScan';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createSecurityRoute, generateRefetchIntervalMs, ROUTES } from 'telivy-constants';
import { isScanApplication, isTopologyEnabled } from 'telivy-selectors';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { ApplicationCompanyView } from '../company';
import { ApplicationsView } from './applications';
import { InventoryData } from './Data';
import { NetworkView } from './network';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    gap: theme.spacing(2),
  },
  table: {
    width: '100%',
  },

  selectsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  select: {},
}));

export type SourceRoute = {
  security: {
    OVERVIEW: string;
    TARGETS: string;
    EXTERNAL_SCAN: string;
    FINDINGS: string;
    FINDING: string;
    BROWSER_PASSWORDS: string;
    TECHSTACK: string;
    PASSWORDS: string;
    VULNERABILITIES: string;
    COMPANY_INFO: string;
    DOWNLOAD: string;
  };
  targets: {
    PARENT: string;
    ROOT: string;
    DOMAINS: string;
    DEVICES: string;
  };
  inventory: {
    PARENT: string;
    ROOT: string;
    company: {
      PARENT: string;
      ROOT: string;
      DETAILS: string;
      FORM: string;
    };
    network: {
      PARENT: string;
      ROOT: string;
      DOMAINS: string;
      DEVICES: string;
      TOPOLOGY: string;
    };
    applications: {
      PARENT: string;
      ROOT: string;
      CLOUD: string;
      BROWSER: string;
      DEVICE: string;
    };
    USERS: string;
    DATA: string;
  };
};

interface Props {
  application: ApplicationWithAgentDTO;
  sourceRoute: SourceRoute;
}

export const ApplicationInventoryView = ({ application, sourceRoute }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: agent } = useCurrentAgent();
  const deepScanId = application.deepScanId || '';

  const { data: deepScan } = useDeepScan(deepScanId);
  // const enableCharts = useChartsEnabled(deepScan, application);

  const securityScanId = 'latest';
  const { data: securityScan, isLoading } = useApplicationSecurityScan(application.id, securityScanId, {
    refetchInterval: (data) =>
      data && data?.status !== SecurityScanStatus.COMPLETED ? generateRefetchIntervalMs() : false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const { data: deepScanTargets, isLoading: isTargetsLoading } = useDeepScanTargets(deepScanId, {
    enabled: Boolean(deepScanId),
  });

  const { data: deepScanNetworkHosts } = useDeepScanNetworkHosts(deepScanId, {
    enabled: Boolean(deepScanId),
  });

  const { data: report } = useDeepScanParsedReports(deepScan?.id || '', {
    enabled: Boolean(deepScan?.id),
  });

  const { data: dataRiskDistribution, isLoading: isLoadingDataRisk } = useDeepScanDataRiskDistribution(
    deepScan?.id || '',
    { enabled: Boolean(deepScan?.id) },
  );

  const totalDataRiskDistribution = useMemo(() => {
    const all = dataRiskDistribution?.reduce((acc: { [key: string]: DataRiskDistributionDTO }, d) => {
      acc[d.piiType] ||= {
        piiType: d.piiType,
        hostname: d.hostname,
        sensitiveFiles: 0,
        matchesCount: 0,
        totalRisk: 0,
      };

      acc[d.piiType].sensitiveFiles += d.sensitiveFiles;
      acc[d.piiType].matchesCount += d.matchesCount;
      acc[d.piiType].totalRisk += d.totalRisk;

      return acc;
    }, {});

    return all && Object.values(all);
  }, [dataRiskDistribution]);

  const { data: installedApps } = useDeepScanInstalledApplications(deepScanId, {
    enabled: Boolean(deepScanId),
  });

  const viewOptions: Option<ValueOf<Omit<SourceRoute['inventory'], 'network' | 'applications' | 'company'>>>[] =
    React.useMemo(() => {
      const options = [];
      let allDomains: string[] = [];

      options.push({
        label: `Company`,
        value: createSecurityRoute(sourceRoute.inventory.company.ROOT, application.id),
      });

      if (securityScan && securityScan.domain !== 'nodomain.local') {
        allDomains = [securityScan.domain]
          .concat(securityScan.subDomains || [], securityScan.ipAddresses || [], securityScan.otherDomains || [])
          .filter((d) => (securityScan.excludedTargets || []).indexOf(d) <= -1);
      }

      options.push({
        label: `Network (${
          (deepScanNetworkHosts ? uniqBy(deepScanNetworkHosts, 'ipAddress').length : 0) + uniq(allDomains).length
        })`,
        value: createSecurityRoute(sourceRoute.inventory.network.ROOT, application.id),
      });

      // if (isAgentAnAdmin(agent)) {
      // options.push({
      //   label: `Inventory (${
      //     (deepScanNetworkHosts ? uniqBy(deepScanNetworkHosts, 'ipAddress').length : 0) +
      //     (totalDataRiskDistribution?.length || 0)
      //   })`,
      //   value: createSecurityRoute(sourceRoute.assets.inventory.ROOT, application.id),
      // });
      // }

      if (deepScan) {
        options.push({
          label: `Applications (${
            (deepScan.m365Connected ? 1 : 0) +
            (deepScan.gwsConnected ? 1 : 0) +
            (report?.applicationsRiskReport?.length || 0) +
            (installedApps?.length || 0)
          })`,
          value: createSecurityRoute(sourceRoute.inventory.applications.ROOT, application.id),
        });
      }

      options.push({
        label: `Data (${totalDataRiskDistribution?.length || 0})`,
        value: createSecurityRoute(sourceRoute.inventory.DATA, application.id),
      });

      return options;
    }, [
      securityScan,
      sourceRoute,
      application,
      // deepScanTargets,
      deepScanNetworkHosts,
      deepScan,
      report,
      totalDataRiskDistribution,
      installedApps,
    ]);

  if (!isScanApplication(application)) {
    return (
      <ApplicationViewContainer>
        <EmptyState
          title='Not enabled'
          text={
            <>
              Please reach out to <a href='mailto:brokerage@telivy.com'>brokerage@telivy.com</a> for access.
            </>
          }
          icon={<LockOutlinedIcon />}
        />
      </ApplicationViewContainer>
    );
  }

  if (isLoading || isTargetsLoading || !deepScanTargets) {
    return <LoadingContainer />;
  }

  return (
    <ApplicationViewContainer>
      <Helmet>
        <title>Assets - {application?.applicationResponse?.organization_name}</title>
      </Helmet>

      <div className={classes.selectsContainer}>
        <RadioSelect<ValueOf<Omit<SourceRoute['inventory'], 'applications' | 'network' | 'company'>>>
          responsive
          options={viewOptions}
          className={classes.select}
          selectedValue={location.pathname}
          onOptionSelect={(opt) => navigate(opt.value)}
          selectedFn={(optionValue, selectedValue) => (selectedValue ? selectedValue.includes(optionValue) : false)}
        />
      </div>

      <Routes>
        <Route
          path={sourceRoute.inventory.company.PARENT}
          element={<ApplicationCompanyView application={application} sourceRoute={ROUTES.agent.application} />}
        />

        <Route
          path={sourceRoute.inventory.network.PARENT}
          element={
            <NetworkView
              application={application}
              sourceRoute={sourceRoute.inventory.network}
              securityScan={securityScan}
              deepScanTargets={deepScanTargets}
              deepScanNetworkHosts={deepScanNetworkHosts}
              isTopologyEnabled={isTopologyEnabled(agent)}
              deepScan={deepScan}
            />
          }
        />

        <Route
          path={sourceRoute.inventory.applications.PARENT}
          element={
            <ApplicationsView
              deepScan={deepScan}
              application={application}
              sourceRoute={sourceRoute.inventory.applications}
              applicationRiskReports={report?.applicationsRiskReport}
              installedApps={installedApps}
            />
          }
        />

        <Route
          path={sourceRoute.inventory.DATA}
          element={
            <InventoryData
              deepScan={deepScan}
              totalDataRiskDistribution={totalDataRiskDistribution}
              isLoadingDataRisk={isLoadingDataRisk}
            />
          }
        />

        <Route index element={<Navigate replace to={sourceRoute.inventory.network.ROOT} />} />
      </Routes>
    </ApplicationViewContainer>
  );
};
