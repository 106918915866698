import { makeStyles } from '@material-ui/core';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { Badge } from 'components/Badge';
import { Column, Table } from 'components/Table';
import { VerifyEmail } from 'components/VerifyEmail';
import dayjs from 'dayjs';
import { AgentDTO } from 'dtos/agent';
import { ALERT_CATEGORIES, AlertCategory, AlertDTO } from 'dtos/alert';
// import { SortOrder } from 'dtos/application';
import { useAlertsHistoryChart } from 'hooks/charts/alerts/useAlertsHistoryChart';
import { intersection } from 'lodash';
import moment from 'moment';
import { useAlerts } from 'queries/useAlerts';
import queryString from 'query-string';
import React, { useCallback, useMemo, useState } from 'react';
// import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { AUTH0_DOMAIN, DEMO_ID, ROUTES } from 'telivy-constants';
import { ScoreSecurityCard } from 'templates/SecurityAssessments/components/common/ScoreSecurityCard';
import { LIMITS } from 'views/agent/views/application-details/views/monitoring/RiskAssessmentHistory';

import { AvailableFilter, Filters, QueryParams } from './Filters';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.spacing()}px ${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(4)}px`,
    boxSizing: 'border-box',
  },
  embed: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  filters: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    // marginRight: theme.spacing(3),
    // marginLeft: theme.spacing(),
  },
  filtersEmbed: {
    paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
  },
  overallScoreBadge: {
    marginRight: theme.spacing(1),
  },
  checkbox: {
    padding: `0 ${theme.spacing(1)}px`,
  },
  edit: {
    paddingTop: theme.spacing(0.05),
    paddingBottom: theme.spacing(0.05),
    marginLeft: theme.spacing(1),
  },
  section: {
    marginBottom: theme.spacing(1),
  },
  headerRow: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  securityCard: {
    flex: 1,
  },
  chartContainer: {
    flex: 1,
    // borderRadius: theme.spacing(1),
    // border: `1px solid ${COLORS.GREY_5}`,
    backgroundColor: `rgba(#F3F7F9, 0.5)`,
    // padding: theme.spacing(3),
  },
  lineChart: {
    height: 300,
  },
}));

const generateColumns = (): Array<Column<AlertDTO, keyof AlertDTO>> => {
  const remainingSpacePercentages = 80;
  const statusColumnWidth = 120;

  const baseColumns: Array<Column<AlertDTO, keyof AlertDTO>> = [
    {
      title: 'Status',
      width: `calc(X)`,
      render: (alert) => (
        <Badge bordered variant={alert.read ? 'green' : 'yellow'} style={{ minWidth: 50, width: 50 }}>
          {alert.read ? 'Read' : 'Unread'}
        </Badge>
      ),
    },
    {
      title: 'Date',
      width: `calc(X)`,
      render: (alert) => dayjs(alert.createdAt).format('M/D/YYYY'),
    },
    {
      title: 'Category',
      width: `calc(X)`,
      render: (alert) => ALERT_CATEGORIES[alert.category],
    },
    {
      title: 'Alert Details',
      width: '35%',
      render: (alert) => alert.data?.content,
    },
    {
      title: 'Company',
      width: `calc(X)`,
      render: (alert) => alert.application?.applicationResponse?.organization_name,
    },
  ];

  // baseColumns.push({
  //   title: 'Actions',
  //   width: `calc(X)`,
  //   render: (alert) => (
  //     <>
  //       <Button variant='outlined' color='default' onClick={() => handleViewAssessment(alert)} className={actionClass}>
  //         View Details
  //       </Button>
  //     </>
  //   ),
  // });

  return baseColumns.map((column) => ({
    ...column,
    width:
      column.width === 'calc(X)'
        ? `calc(${remainingSpacePercentages / baseColumns.length}% - ${statusColumnWidth}px)`
        : column.width,
  }));
};

interface Props {
  agent: AgentDTO;
  applicationId?: string;
}

const PER_PAGE = 50;
export const AlertsHistoryView = ({ agent, applicationId }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { search } = useLocation();
  // const { data: securityApplicationVersion } = useSecurityApplicationVersion();
  // const isAdmin = isAgentAnAdmin(agent);
  // const isAgencyAdmin = agent.role === 'agency_admin' || agent.role === 'telivy_admin';

  // const [selectedAlert, setSelectedAlert] = React.useState<AlertDTO | null>(null);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedLimit, setSelectedLimit] = useState<keyof typeof LIMITS>('30 days');

  const filterDays = useMemo(() => {
    return (selectedLimit && LIMITS[selectedLimit]) || 30;
  }, [selectedLimit]);

  const filtersList: AvailableFilter<QueryParams> = useMemo(() => {
    const filters = {
      unread: {
        label: 'Show Unread',
        icon: <CheckCircleOutlineRoundedIcon />,
        options: [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
      },
      alertCategory: {
        label: 'Category',
        icon: <CheckCircleOutlineRoundedIcon />,
        options: Object.values(AlertCategory).map((category) => ({
          label: ALERT_CATEGORIES[category],
          value: category || null || undefined,
        })),
      },
      creationDateDays: {
        label: 'Creation Date',
        icon: <CalendarTodayRoundedIcon />,
        options: [
          { label: 'In the last 7 days', value: 7 },
          { label: 'In the last 14 days', value: 14 },
          { label: 'In the last 21 days', value: 21 },
          { label: 'In the last 30 days', value: 30 },
        ],
      },
    };

    return filters;
  }, []);

  const queryParams = useMemo(
    () =>
      queryString.parse(search, {
        parseNumbers: true,
      }) as unknown as QueryParams,
    [search],
  );

  // default params values
  const params = useMemo(
    () => ({
      q: queryParams.q || '',
      page: queryParams.page || 0,
      unread: queryParams.unread,
      alertCategory: queryParams.alertCategory,
      creationDateDays: queryParams.creationDateDays,
    }),
    [queryParams],
  );

  const { data, isLoading } = useAlerts({
    q: params.q,
    limit: PER_PAGE,
    offset: params.page * PER_PAGE,
    unread: params.unread || undefined,
    alertCategory: params.alertCategory || undefined,
    creationDateDays: params.creationDateDays,
    demo: AUTH0_DOMAIN !== 'auth.telivy.com', //applicationId === DEMO_ID,
  });

  const handleUpdateUrl = useCallback(
    (paramsToUpdate: Partial<QueryParams>) => {
      const paramsToUpdateList = Object.keys(paramsToUpdate);
      const filterListKeys = Object.keys(filtersList);
      const parsedParams = { ...queryParams, ...paramsToUpdate };

      // if you add any of the filterList or q -> change the page to 0
      if (intersection(filterListKeys, paramsToUpdateList).length || paramsToUpdateList.includes('q')) {
        parsedParams.page = 0;
      }

      const urlWithParams = queryString.stringify(parsedParams, {
        skipEmptyString: true,
        skipNull: true,
      });

      navigate(`?${urlWithParams}`, { replace: true });
    },
    [navigate, queryParams, filtersList],
  );

  // const handleViewAssessment = useCallback(
  //   (alert) => {
  //     const urlWithParams = queryString.stringify({
  //       q: alert.orgName,
  //     });
  //     navigate(`${ROUTES.agent.ROOT}/${ROUTES.agent.DASHBOARD}?${urlWithParams}`);
  //   },
  //   [navigate],
  // );

  const columns = useMemo(() => {
    return generateColumns();
  }, []);

  const alertsData = useMemo(() => {
    return (data?.alerts || [])
      .filter(
        (a) =>
          moment(applicationId === DEMO_ID ? '2024-11-04' : undefined).diff(moment(a.createdAt), 'days') <= filterDays,
      )
      .filter((a) => !applicationId || a.applicationId === applicationId);
  }, [data, filterDays, applicationId]);

  const { chart: alertsHistoryChart } = useAlertsHistoryChart(
    !!agent.agency?.enableAlerts,
    alertsData,
    classes.lineChart,
  );

  const criticalVulnerabilities = useMemo(() => {
    return alertsData
      .filter((a) => a.category === AlertCategory.INTERNAL_VULNERABILITIES)
      .map((a) => (a.data?.filteredCves || []).length)
      .reduce((a, b) => a + b, 0);
  }, [alertsData]);

  const typosquattingDomains = useMemo(() => {
    return alertsData
      .filter((a) => a.category === AlertCategory.TYPOSQUATTING)
      .map((a) => (a.data?.filteredFindings || []).length)
      .reduce((a, b) => a + b, 0);
  }, [alertsData]);

  const noMfaM365Users = useMemo(() => {
    return alertsData
      .filter((a) => a.category === AlertCategory.M365_NO_MFA)
      .map((a) => (a.data?.filteredUsers || []).length)
      .reduce((a, b) => a + b, 0);
  }, [alertsData]);

  const failedM365Logins = useMemo(() => {
    return alertsData
      .filter((a) => a.category === AlertCategory.M365_FAILED_LOGINS)
      .map((a) => (a.data?.filteredLogins || []).length)
      .reduce((a, b) => a + b, 0);
  }, [alertsData]);

  const noMfaGwsUsers = useMemo(() => {
    return alertsData
      .filter((a) => a.category === AlertCategory.GWS_NO_MFA)
      .map((a) => (a.data?.filteredGwsUsers || []).length)
      .reduce((a, b) => a + b, 0);
  }, [alertsData]);

  const failedGwsLogins = useMemo(() => {
    return alertsData
      .filter((a) => a.category === AlertCategory.GWS_FAILED_LOGINS)
      .map((a) => (a.data?.filteredGwsLogins || []).length)
      .reduce((a, b) => a + b, 0);
  }, [alertsData]);

  const handleClick = (alert: AlertDTO) => {
    navigate(`${ROUTES.agent.ROOT}/${ROUTES.agent.alerts.ROOT}/${alert.id}`);
  };

  if (agent && !agent.isEmailVerified) {
    return <VerifyEmail />;
  }

  return (
    <div className={applicationId ? classes.embed : classes.root}>
      <Filters
        title='Alerts'
        className={applicationId ? classes.filtersEmbed : classes.filters}
        onFilterChange={(paramsToUpdate) => handleUpdateUrl(paramsToUpdate)}
        currentFilters={params}
        filterList={filtersList}
        applicationIds={selectedIds}
        onBulkAction={() => {
          setSelectedIds([]);
        }}
        selectedLimit={selectedLimit}
        setSelectedLimit={setSelectedLimit}
        applicationId={applicationId}
      />

      <div className={classes.section}>
        <div className={classes.headerRow}>
          {criticalVulnerabilities > 0 && (
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Internal Vulnerabilities'
              skipGrading={(criticalVulnerabilities || 0).toLocaleString()}
              score={criticalVulnerabilities > 0 ? 50 : 100}
            />
          )}
          {typosquattingDomains > 0 && (
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Typo-squatting Domains'
              skipGrading={(typosquattingDomains || 0).toLocaleString()}
              score={typosquattingDomains > 0 ? 50 : 100}
            />
          )}
          {noMfaM365Users > 0 && (
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Microsoft 365 Users without MFA'
              skipGrading={noMfaM365Users.toLocaleString()}
              score={noMfaM365Users > 0 ? 50 : 100}
            />
          )}
          {failedM365Logins > 0 && (
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Microsoft 365 Failed Logins'
              skipGrading={failedM365Logins.toLocaleString()}
              score={failedM365Logins > 0 ? 50 : 100}
            />
          )}
          {noMfaGwsUsers > 0 && (
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Google Workspace Users without MFA'
              skipGrading={noMfaGwsUsers.toLocaleString()}
              score={noMfaGwsUsers > 0 ? 50 : 100}
            />
          )}
          {failedGwsLogins > 0 && (
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Google Workspace Failed Logins'
              skipGrading={failedGwsLogins.toLocaleString()}
              score={failedGwsLogins > 0 ? 50 : 100}
            />
          )}
        </div>
      </div>

      <div className={classes.chartContainer}>{alertsHistoryChart}</div>

      <Table<AlertDTO, keyof AlertDTO>
        data={alertsData}
        columns={columns}
        loading={isLoading}
        onRowClick={handleClick}
        onChange={(pagination) => {
          if (pagination) {
            handleUpdateUrl({ page: pagination.page });
          } else {
            handleUpdateUrl({});
          }
        }}
        rowKey={(alert) => alert.id}
        pagination={{
          page: params.page,
          perPage: PER_PAGE,
          total: data?.alertsCount || 0,
          elementName: 'Alert',
        }}
      />
    </div>
  );
};
