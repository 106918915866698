import { Button, ButtonGroup, ClickAwayListener, MenuItem, MenuList, Paper } from '@material-ui/core';
import { Grow } from '@material-ui/core';
import { Popper } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import cx from 'classnames';
import React, { useRef, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { AvailableFilter } from 'views/agent/views/dashboard/Filters';

const CustomButtonGroup = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    border: `1px solid ${COLORS.GREY_5}`,
    borderRadius: theme.spacing(),
    padding: `0 ${theme.spacing()}px`,
    position: 'relative',
    minHeight: theme.spacing(4.25),

    '&:hover, &:focus': {
      backgroundColor: COLORS.BLUE_3,
      borderColor: 'transparent',

      '& > button': {
        color: COLORS.BLUE_1,
      },
    },
  },
  groupedContainedHorizontal: {
    borderRight: 'none !important',
  },
}))(ButtonGroup);

const CustomButton = withStyles({
  root: {
    background: 'none',
    padding: 0,
    minWidth: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',

    '&:hover': {
      '& $closeIcon': {
        visibility: 'visible',
      },
    },
  },

  iconWrapper: {
    lineHeight: 1,
    marginRight: theme.spacing(1.25),
  },

  noBorder: {
    border: 'none',
  },

  activeButton: {
    backgroundColor: COLORS.BLUE_3,
    borderColor: 'transparent',

    '& > button': {
      color: COLORS.BLUE_1,
    },
  },

  dimmedButton: {
    '& > button': {
      color: COLORS.GREY_3,
    },
  },

  closeIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    position: 'absolute',
    width: '100%',
    right: theme.spacing(3.5),
    color: COLORS.RED_1,
    visibility: 'hidden',
    borderRadius: theme.spacing(),
    height: '100%',
    background: 'linear-gradient(90deg, rgba(255,255,255,0.1) 70%, rgba(246,245,250,1) 85%)',
    zIndex: 3,
    pointerEvents: 'none',

    '& > svg': {
      height: 20,
      width: 20,
    },
  },

  boldText: {
    ...TYPOGRAPHY.SMALL_BOLD,
  },
}));

interface CurrentFilter<T> {
  name?: keyof T;
  value?: T[keyof T];
}

interface Props<Params> {
  currentFilter: CurrentFilter<Params>;
  onFilterChange: (paramsToUpdate: Partial<Params>) => void;
  availableFilters?: Array<keyof AvailableFilter<Params>>;
  filterList: AvailableFilter<Params>;
}

export const NewFilterButton = function <Params>({
  currentFilter,
  onFilterChange,
  availableFilters,
  filterList,
}: Props<Params>) {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedFilter, setSelectedFilter] = useState<keyof Params>();

  const handleCloseMenu = (event: React.MouseEvent<Document>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenMenu(false);
  };

  const handleToggleMenu = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleFilterChange = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, filter: keyof Params) => {
    setSelectedFilter(filter);
  };

  const handleOptionChange = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, option: Params[keyof Params]) => {
    onFilterChange({ [(selectedFilter as string) || (currentFilter.name as string)]: option } as Partial<Params>);
    setSelectedFilter(undefined);
    setOpenMenu(false);
  };

  const handleRemoveFilter = () => {
    onFilterChange({ [(selectedFilter as string) || (currentFilter.name as string)]: undefined } as Partial<Params>);
  };

  return (
    <div className={classes.buttonContainer}>
      {currentFilter.name && currentFilter.value && (
        <span className={classes.closeIcon}>
          <CancelRoundedIcon />
        </span>
      )}
      <CustomButtonGroup
        variant='contained'
        ref={anchorRef}
        aria-label='split button'
        className={cx(
          (!currentFilter.name || !currentFilter.value) && classes.noBorder,
          openMenu && classes.activeButton,
        )}
        onClick={currentFilter.name && currentFilter.value ? handleRemoveFilter : undefined}
      >
        <CustomButton
          size='small'
          aria-controls={openMenu ? 'filter-menu' : undefined}
          aria-expanded={openMenu ? 'true' : undefined}
          aria-label='select filter'
          aria-haspopup='menu'
          onClick={handleToggleMenu}
        >
          {currentFilter.name || selectedFilter ? (
            currentFilter.value ? (
              <>
                <span className={classes.boldText}>{filterList[currentFilter.name].label}</span>
                <span style={{ marginLeft: 4, marginRight: 4 }}>is</span>
              </>
            ) : (
              `${filterList[selectedFilter].label} is... `
            )
          ) : (
            <>
              <AddRoundedIcon /> Add Filter
            </>
          )}
        </CustomButton>

        {currentFilter.name && (
          <CustomButton
            size='small'
            aria-controls={openMenu ? 'option-menu' : undefined}
            aria-expanded={openMenu ? 'true' : undefined}
            aria-label='select option'
            aria-haspopup='menu'
            onClick={handleToggleMenu}
          >
            <span className={classes.boldText}>
              {
                filterList[currentFilter.name]?.options.find(
                  (option) => String(option.value) === String(currentFilter.value),
                )?.label
              }
            </span>
            <ArrowDropDownRoundedIcon />
          </CustomButton>
        )}
      </CustomButtonGroup>

      <Popper
        open={openMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 5 }}
        placement='bottom-start'
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              marginTop: 8,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={(e) => handleCloseMenu(e)}>
                <MenuList id='filter-menu' autoFocusItem>
                  {/* filter name options */}
                  {!currentFilter.name &&
                    !selectedFilter &&
                    (availableFilters || []).map((filter, index) => (
                      <MenuItem key={index} onClick={(event) => handleFilterChange(event, filter)}>
                        {/*<span className={classes.iconWrapper}>{filterList[filter]?.icon}</span>*/}
                        {filterList[filter]?.label}
                      </MenuItem>
                    ))}

                  {/* filter options */}
                  {(currentFilter.name || selectedFilter) &&
                    filterList[selectedFilter]?.options.map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={(event) => handleOptionChange(event, option.value as Params[keyof Params])}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
