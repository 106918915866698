import { Button, makeStyles, Theme } from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import cx from 'classnames';
import { CustomTextField } from 'components/CustomTextField';
import { LoadingContainer } from 'components/LoadingContainer';
import { Modal } from 'components/Modal';
import { RadioSelect } from 'components/RadioSelect';
import { SingleFilter } from 'components/SingleFilter';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import { ApplicationWithAgentDTO, MonitoringSnapshotDTO, SecurityScanDTO } from 'dtos/application';
import { DeepScanTargetDTO, InstalledAppsStats, NmapVulnerabilityData, TopNmapVulnerability } from 'dtos/deep-scan';
import { SecurityFindingBySlugDTO, SecurityScanType, SecuritySeverity } from 'dtos/security-finding';
// import { useByRiskBySeverityChart } from 'hooks/charts/useByRiskBySeverityChart';
import { useMonitoringNetworkSecurityChart } from 'hooks/charts/monitoring/useNetworkSecurityChart';
import { useExternalScanFindings } from 'hooks/external-scan/useExternalScanFindings';
import { useExternalScanPreviousFindings } from 'hooks/external-scan/useExternalScanPreviousFindings';
import { useChartImage } from 'hooks/useChartImage';
// import { useChartsEnabled } from 'hooks/useChartsEnabled';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { useApplicationSecurityChartsUrls } from 'queries/useCharts';
import {
  useDeepScan,
  useDeepScanInstalledApplications,
  useDeepScanInternalVulnerabilities,
  useDeepScanReportData,
  useDeepScanTargetsNmap,
} from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
import { createSecurityRoute } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { LIMITS } from 'views/agent/views/application-details/views/monitoring/RiskAssessmentHistory';

import { SourceRoute } from '../..';
import { ScoreSecurityCard } from '../../components/common/ScoreSecurityCard';
import { renderScanResults } from '../../components/ExternalScanItem';
import { ScoreBox, ScoreRanking } from '../../components/ScoreBox';
// import { VulnerabilitiesDetailsModal } from '../../components/VulnerabilitiesDetailsModal';
import {
  // NmapVulnerabilitiesDetailsModal,
  SEVERITY,
  SEVERITY_TO_SCORE_RANGE,
} from '../../components/VulnerabilitiesDetailsModal/NmapVulnerabilitiesDetailsModal';
import { NmapVulnerabilityDetailsModal } from '../../components/VulnerabilitiesDetailsModal/NmapVulnerabilityDetailsModal';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(1),
  },

  findings: {
    color: COLORS.GREY_3,
  },

  section: {
    marginBottom: theme.spacing(4),
  },

  summary: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(2),
  },

  item: {
    border: `solid 1px ${COLORS.GREY_5}`,
    backgroundColor: 'rgba(249, 251, 252, 0.5)',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  description: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_2,
    marginTop: theme.spacing(1),
  },

  number: {
    ...TYPOGRAPHY.TITLE_2,
    // fontSize: 48,
    color: COLORS.TEXT,
  },

  red: {
    color: COLORS.RED_1,
  },
  yellow: {
    color: COLORS.YELLOW_1,
  },
  green: {
    color: COLORS.GREEN_1,
  },
  blue: {
    color: COLORS.BLUE_1,
  },

  scanResultsWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(1.25),
    },
  },

  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  boxBorder: {
    border: 'solid 1px #efefef',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flex: 1,
  },
  boxBorderPadding: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
  },

  headerRow: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
  },
  chartContainer: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    '&:first-child': {
      flex: 2,
    },
  },
  chart: {
    height: 380,
  },
  actionButton: {
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
  },

  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  lineChart: {
    height: 400,
  },
  redItalic: {
    color: COLORS.RED_1,
    fontStyle: 'italic',
  },
  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoIcon: {
    color: `${COLORS.GREY_4} !important`,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
    transition: 'color 0.2s ease-in-out',
    fontSize: 16,

    '&:hover': {
      color: `${COLORS.BLUE_1} !important`,
    },
  },
  input: {
    flex: 1,
  },
  securityCard: {
    flex: 1,
  },
}));

interface Props {
  securityScan: SecurityScanDTO;
  applicationId: string;
  sourceRoute: SourceRoute;
  securityScanId: string;
  application: ApplicationWithAgentDTO;
  monitoringSnapshots?: MonitoringSnapshotDTO[];
  selectedAssets: string[];
  setSelectedAssets: (a: string[]) => void;
  allAssets: string[];
  selectedLimit: string;
  setSelectedLimit: (a: string) => void;
  // isAdmin?: boolean;
}

export const calculateScoreLabel = (score?: number) => {
  let rating: ScoreRanking = 'Low';
  if (score && score > 900) {
    rating = 'Low';
  } else if (score && score > 500) {
    rating = 'Medium';
  } else {
    rating = 'High';
  }

  return rating;
};

const PER_PAGE = 10;

const scrollCvesTable = () => {
  const section = document.querySelector('#cves-table');
  section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export const DeepScanNetworkSecurity = ({
  applicationId,
  sourceRoute,
  securityScan,
  securityScanId,
  application,
  monitoringSnapshots,
  selectedAssets,
  setSelectedAssets,
  allAssets,
  selectedLimit,
  setSelectedLimit,
}: Props) => {
  const classes = useStyles();
  // const [sorter, setSorter] = useState<Sorter<keyof NodewareAsset> | undefined>({
  //   key: 'score',
  //   order: SortOrder.ASC,
  // });

  const [selectedTarget, setSelectedTarget] = useState<DeepScanTargetDTO | null>(null);
  const [selectedInstalledApp, setSelectedInstalledApp] = useState<InstalledAppsStats | null>(null);

  const [selectedVulnerability, setSelectedVulnerability] = useState<NmapVulnerabilityData | null>(null);
  const [severity, setSeverity] = useState<SEVERITY | null>(null);
  const [search, setSearch] = useState('');

  const [targetSorter, setTargetSorter] = useState<Sorter<string> | undefined>({
    key: 'nmapScanStats.epssMax',
    order: SortOrder.DESC,
  });

  const [cvesPage, setCvesPage] = useState(0);
  const [cvesSorter, setCvesSorter] = useState<Sorter<string> | undefined>({
    key: 'data.epss',
    order: SortOrder.DESC,
  });

  const [installedAppsPage, setInstalledAppsPage] = useState(0);
  const [installedAppsSorter, setInstalledAppsSorter] = useState<Sorter<string> | undefined>({
    key: 'data.nmapScanStats.epssMax',
    order: SortOrder.DESC,
  });

  const {
    findingsByType,
    isErrorPatchingCadence,
    isErrorScanNetwork,
    isErrorScanIpReputation,
    isLoadingPatchingCadence,
    isLoadingScanNetwork,
    isLoadingScanIpReputation,
  } = useExternalScanFindings({ securityScan });

  const { previousFindingsByType } = useExternalScanPreviousFindings({ securityScan });

  const getFindingUrl = (finding: SecurityFindingBySlugDTO) =>
    createSecurityRoute(sourceRoute.security.FINDING, applicationId, securityScanId, finding.slug);

  const deepScanId = application.deepScanId || '';
  const { data: deepScan } = useDeepScan(deepScanId, {
    enabled: Boolean(deepScanId),
  });

  // const { data: nodewareAssets, isLoading: isLoadingNodeware } = useDeepScanNodeware(deepScanId, {
  //   enabled: Boolean(deepScanId && deepScan?.nodewareId),
  // });
  // const enableCharts = useChartsEnabled(deepScan, application);

  const { data: deepScanTargets, isLoading: isTargetsLoading } = useDeepScanTargetsNmap(deepScanId, {
    enabled: Boolean(deepScanId),
  });

  const { data: internalVulnerabilities, isLoading: isVulnerabilitiesLoading } = useDeepScanInternalVulnerabilities(
    deepScanId,
    { enabled: Boolean(deepScanId) },
  );

  const { data: installedApps, isLoading: isInstalledAppsLoading } = useDeepScanInstalledApplications(deepScanId, {
    enabled: Boolean(deepScanId),
  });

  const { data: reportData } = useDeepScanReportData(application.deepScanId || '', {
    enabled: Boolean(application.deepScanId),
  });

  // const columns = useMemo((): Column<NodewareAsset, keyof NodewareAsset>[] => {
  //   return [
  //     {
  //       title: 'Host',
  //       sortKey: 'hostname',
  //       render: (row) => (row.hostname || row.name).replace(' (Agent)', ''),
  //     },
  //     {
  //       title: 'Mac Address',
  //       sortKey: 'macAddress',
  //       render: (row) => row.macAddress,
  //     },
  //     {
  //       title: 'High Severity',
  //       sortKey: 'totals.critical',
  //       render: (row) => (row.totals?.critical || 0) + (row.totals?.high || 0),
  //     },
  //     {
  //       title: 'Medium Severity',
  //       sortKey: 'totals.medium',
  //       render: (row) => row.totals?.medium || 0,
  //     },
  //     {
  //       title: 'Low Severity',
  //       sortKey: 'totals.low',
  //       render: (row) => row.totals?.low || 0,
  //     },
  //     {
  //       title: 'Informational',
  //       sortKey: 'totals.info',
  //       render: (row) => row.totals?.info || 0,
  //     },
  //     {
  //       title: 'Actions',
  //       // sortKey: 'score',
  //       render: (row) => {
  //         // const rating: ScoreRanking = calculateScoreLabel(row.score);

  //         return (
  //           <div className={classes.row}>
  //             {/*<ScoreBox ranking={rating} label={`${row.score}`} />*/}
  //             <VulnerabilitiesDetailsModal
  //               nodewareAsset={row}
  //               deepScanId={deepScanId}
  //               label='Details'
  //               className={classes.actionButton}
  //             />
  //           </div>
  //         );
  //       },
  //     },
  //   ];
  // }, [classes, deepScanId]);

  // const { sortedNodewareAssets } = useByRiskBySeverityChart(enableCharts, nodewareAssets, sorter);
  const { data: charts } = useApplicationSecurityChartsUrls(application.id);
  // const nodewareAssetsCharts = useChartImage(
  //   { className: classes.chart, title: 'Vulnerability by Devices' },
  //   charts?.nodewareAssets,
  //   deepScan,
  //   application,
  // );
  // const nodewareAssetsByRiskChart = useChartImage(
  //   { className: classes.chart, title: 'Device Score by Risk Level' },
  //   charts?.nodewareAssetsByRisk,
  //   deepScan,
  //   application,
  // );

  const internalVulnerabilitiesChart = useChartImage(
    { className: classes.chart, title: 'Vulnerability by Devices' },
    charts?.internalVulnerabilities,
    deepScan,
    application,
  );
  const targetsByInternalVulnerabilitiesRiskChart = useChartImage(
    { className: classes.chart, title: 'Vulnerability by Risk Level' },
    charts?.targetsByInternalVulnerabilitiesRisk,
    deepScan,
    application,
  );

  const { chart: networkSecurityChart } = useMonitoringNetworkSecurityChart(
    application,
    application.monitoringEnabled,
    monitoringSnapshots,
    selectedAssets,
    false,
    classes.lineChart,
  );

  const targetColumns = useMemo((): Column<DeepScanTargetDTO, string>[] => {
    return [
      {
        title: 'Host',
        sortKey: 'hostname',
        render: (row) => row.hostname,
      },
      {
        title: 'Mac Address',
        sortKey: 'macAddress',
        render: (row) => row.macAddress,
      },
      {
        title: 'Scan Status',
        render: (row) => {
          let ranking: ScoreRanking = 'Medium';
          let status = 'Pending';

          if (
            row.nmapScanStats &&
            ((row.telivyAgentDetails?.scan_applications_total_found !== undefined &&
              row.telivyAgentDetails?.scan_applications_total_found !== null) ||
              Object.values(row.browserPasswordExecutionDetails || {}).filter(
                (b) => b.scan_applications_total_found !== undefined && b.scan_applications_total_found !== null,
              ).length > 0)
          ) {
            ranking = 'Low';
            status = 'Finished';
          } else if (
            row.telivyAgentDetails?.scan_vulnerabilities_error ||
            row.telivyAgentDetails?.scan_applications_error
          ) {
            ranking = 'High';
            status = 'Error';
          } else if (!row.telivyAgentDetails?.scan_network_started || !row.telivyAgentDetails?.scan_applications_date) {
            ranking = 'None';
            status = 'Not started';
          }

          return <ScoreBox ranking={ranking} label={status} />;
        },
      },
      {
        title: (
          <span>
            Max EPSS
            <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title='EPSS'>
              <p>
                The EPSS (Exploit Prediction Scoring System) score represents the probability of exploitation in the
                wild during the next 30 days in the range from 0 to 1. For example, a CVE with EPSS of 0.96 has 96%
                chance to be exploited in the following 30 days.
              </p>
            </Modal>
          </span>
        ),
        sortKey: 'nmapScanStats.epssMax',
        render: (row) => row.nmapScanStats?.epssMax && `${(row.nmapScanStats?.epssMax * 100).toFixed(2)}%`,
      },
      {
        title: 'High Severity',
        sortKey: 'nmapScanStats.cveCounts.high',
        render: (row) => row.nmapScanStats?.cveCounts?.high,
      },
      {
        title: 'Medium Severity',
        sortKey: 'nmapScanStats.cveCounts.medium',
        render: (row) => row.nmapScanStats?.cveCounts?.medium,
      },
      {
        title: 'Low Severity',
        sortKey: 'nmapScanStats.cveCounts.low',
        render: (row) => row.nmapScanStats?.cveCounts?.low,
      },
      {
        title: 'Informational',
        sortKey: 'nmapScanStats.cveCounts.info',
        render: (row) => row.nmapScanStats?.cveCounts?.info,
      },
      {
        title: 'Actions',
        render: (row) => {
          return (
            row.nmapScanStats && (
              <Button
                className={classes.actionButton}
                onClick={() => {
                  setSelectedTarget(row);
                  scrollCvesTable();
                }}
              >
                Show CVEs
              </Button>
            )
          );
        },
      },
    ];
  }, [classes]);

  const sortedDeepScanTargets = useMemo(() => {
    if (targetSorter && deepScanTargets) {
      const notNullTargets = deepScanTargets.filter((d) => d.nmapScanStats?.epssMax);
      const nullTargets = deepScanTargets.filter((d) => !d.nmapScanStats?.epssMax);

      const notNullData = sortBy(notNullTargets, targetSorter.key);
      const nullData = sortBy(nullTargets, targetSorter.key);

      if (targetSorter.order === SortOrder.DESC) {
        return ([] as DeepScanTargetDTO[]).concat(notNullData.reverse(), nullData.reverse());
      }

      return ([] as DeepScanTargetDTO[]).concat(notNullData, nullData);
    }

    return deepScanTargets;
  }, [deepScanTargets, targetSorter]);

  const installedAppsColumns = useMemo((): Column<InstalledAppsStats, string>[] => {
    return [
      {
        title: 'Name',
        sortKey: 'data.name',
        render: (row) => row.data.name,
      },
      {
        title: 'Version',
        sortKey: 'data.version',
        render: (row) => row.data.version,
      },
      {
        title: 'Assets',
        sortKey: 'assets',
        render: (row) => row.assets,
      },
      {
        title: (
          <span>
            Max EPSS
            <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title='EPSS'>
              <p>
                The EPSS (Exploit Prediction Scoring System) score represents the probability of exploitation in the
                wild during the next 30 days in the range from 0 to 1. For example, a CVE with EPSS of 0.96 has 96%
                chance to be exploited in the following 30 days.
              </p>
            </Modal>
          </span>
        ),
        sortKey: 'data.nmapScanStats.epssMax',
        render: (row) => row.data.nmapScanStats?.epssMax && `${(row.data.nmapScanStats?.epssMax * 100).toFixed(2)}%`,
      },
      {
        title: 'High Severity',
        sortKey: 'data.nmapScanStats.cveCounts.high',
        render: (row) => row.data.nmapScanStats?.cveCounts?.high,
      },
      {
        title: 'Medium Severity',
        sortKey: 'data.nmapScanStats.cveCounts.medium',
        render: (row) => row.data.nmapScanStats?.cveCounts?.medium,
      },
      {
        title: 'Low Severity',
        sortKey: 'data.nmapScanStats.cveCounts.low',
        render: (row) => row.data.nmapScanStats?.cveCounts?.low,
      },
      {
        title: 'Informational',
        sortKey: 'data.nmapScanStats.cveCounts.info',
        render: (row) => row.data.nmapScanStats?.cveCounts?.info,
      },
      {
        title: 'Actions',
        render: (row) => {
          return (
            row.data.nmapScanStats && (
              <Button
                className={classes.actionButton}
                onClick={() => {
                  setSelectedInstalledApp(row);
                  scrollCvesTable();
                }}
              >
                Show CVEs
              </Button>
            )
          );
        },
      },
    ];
  }, [classes]);

  const sortedInstalledApps = useMemo(() => {
    if (installedAppsSorter && installedApps) {
      const notNullTargets = installedApps.filter(
        (d) => Math.max(...Object.values(d.data.nmapScanStats?.cveCounts || {})) > 0,
      );
      const notNullData = sortBy(notNullTargets, installedAppsSorter.key);

      if (installedAppsSorter.order === SortOrder.DESC) {
        return notNullData.reverse();
      }

      return notNullData;
    }

    return installedApps;
  }, [installedApps, installedAppsSorter]);

  const installedAppsPageData = useMemo(() => {
    return (sortedInstalledApps || []).slice(installedAppsPage * PER_PAGE, installedAppsPage * PER_PAGE + PER_PAGE);
  }, [installedAppsPage, sortedInstalledApps]);

  const cvesColumns = useMemo((): Column<TopNmapVulnerability, string>[] => {
    return [
      {
        width: 180,
        title: '# / CVE',
        sortKey: 'data.id',
        render: (row) =>
          row.data.score && row.data.id !== 'INFO' ? (
            <a href={`https://nvd.nist.gov/vuln/detail/${row.data.id}`} target='_blank' rel='noreferrer'>
              {row.data.id}
            </a>
          ) : (
            'INFO'
          ),
      },
      {
        title: 'Title',
        sortKey: 'data.description',
        render: (row) =>
          row.data.title ||
          `${row.data.description?.slice(0, 50)}${(row.data.description?.length ?? 0) > 50 ? '...' : ''}`,
      },
      {
        title: 'Source',
        sortKey: 'data.foundIn.product',
        render: (row) =>
          row.data?.score ? (
            <span>
              {row.data?.foundIn?.product}
              <br />
              {row.data?.foundIn?.version}
            </span>
          ) : row.data?.foundIn?.port ? (
            `Port ${row.data?.foundIn?.port}`
          ) : (
            ''
          ),
      },
      {
        title: 'Published Date',
        sortKey: 'data.publishedDate',
        render: (row) => (row.data.publishedDate ? moment(row.data.publishedDate).format('DD MMM YYYY') : null),
      },
      {
        title: (
          <span>
            EPSS
            <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title='EPSS'>
              <p>
                The EPSS (Exploit Prediction Scoring System) score represents the probability of exploitation in the
                wild during the next 30 days in the range from 0 to 1. For example, a CVE with EPSS of 0.96 has 96%
                chance to be exploited in the following 30 days.
              </p>
            </Modal>
          </span>
        ),
        sortKey: 'data.epss',
        render: (row) => row.data.epss && `${(row.data.epss * 100).toFixed(2)}%`,
      },
      {
        title: 'CVSS',
        sortKey: 'data.score',
        render: (row) => row.data.score,
      },
      {
        width: 80,
        title: 'Severity',
        render: (row) => {
          let color = classes.green;
          let label = row.data.score || 'Info';
          if (row.data.score && row.data.score > SEVERITY_TO_SCORE_RANGE[SEVERITY.HIGH][0]) {
            color = classes.red;
            label = 'High';
          } else if (row.data.score && row.data.score > SEVERITY_TO_SCORE_RANGE[SEVERITY.MEDIUM][0]) {
            color = classes.yellow;
            label = 'Medium';
          } else if (row.data.score && row.data.score > SEVERITY_TO_SCORE_RANGE[SEVERITY.LOW][0]) {
            color = classes.blue;
            label = 'Low';
          }

          return (
            <div className={classes.row}>
              <b className={cx(classes.scoreBox, color)}>{label}</b>
            </div>
          );
        },
      },
      {
        title: 'Assets',
        sortKey: 'assets',
        render: (row) => row.assets,
      },
    ];
  }, [classes]);

  const sortedDeepScanInternalVulnerabilities = useMemo(() => {
    let filteredData = internalVulnerabilities || [];

    if (selectedTarget) {
      filteredData = filteredData.filter((t) => t.assetsDetails.filter((t) => t.id === selectedTarget.id).length > 0);
    }

    if (selectedInstalledApp) {
      filteredData = filteredData.filter(
        (t) =>
          (selectedInstalledApp.data.nmapScanParsed?.softwares?.findings || []).filter((f) => f.id === t.data.id)
            .length > 0,
      );
    }

    if (severity) {
      let minFound: number | undefined = undefined;
      let maxFound: number | undefined = undefined;
      filteredData = filteredData.filter((p) => {
        if (severity === SEVERITY.INFO) return !p.data.score;

        const score = parseFloat(`${p.data.score}`);
        const [min, max] = SEVERITY_TO_SCORE_RANGE[severity];

        if (minFound === undefined && score <= min) {
          minFound = score;
        }

        if (maxFound === undefined && score <= max) {
          maxFound = score;
        }

        return score > min && score <= max;
      });
    }

    if (search) {
      filteredData = filteredData.filter((p) => {
        const titleMatch = p.data.title?.toLowerCase().includes(search.toLowerCase());
        const descMatch = p.data.description?.toLowerCase().includes(search.toLowerCase());
        const idMatch = p.data.id?.toLowerCase().includes(search.toLowerCase());

        return titleMatch || idMatch || descMatch;
      });
    }

    if (cvesSorter && filteredData) {
      const notNullCves = filteredData.filter((d) => d.data.score);
      const nullCves = filteredData.filter((d) => !d.data.score);

      const notNullData = sortBy(notNullCves, cvesSorter.key);
      const nullData = sortBy(nullCves, cvesSorter.key);

      if (cvesSorter.order === SortOrder.DESC) {
        return ([] as TopNmapVulnerability[]).concat(notNullData.reverse(), nullData.reverse());
      }

      return ([] as TopNmapVulnerability[]).concat(notNullData, nullData);
    }

    return filteredData;
  }, [internalVulnerabilities, cvesSorter, search, severity, selectedTarget, selectedInstalledApp]);

  const cvesPageData = useMemo(() => {
    return (sortedDeepScanInternalVulnerabilities || []).slice(cvesPage * PER_PAGE, cvesPage * PER_PAGE + PER_PAGE);
  }, [cvesPage, sortedDeepScanInternalVulnerabilities]);

  const criticalVulnerabilities = useMemo(() => {
    return (deepScanTargets || []).map((t) => t.nmapScanStats?.cveCounts?.high || 0).reduce((a, b) => a + b, 0);
  }, [deepScanTargets]);

  const isLoadingAny = !securityScan || isTargetsLoading || isVulnerabilitiesLoading;

  if (isLoadingAny) {
    return <LoadingContainer />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>Network Security</div>

      {/*<div className={classes.section}>
        <div className={classes.title}>
          Internal Vulnerabilities
          <span className={classes.findings}> ({sortedNodewareAssets.length} devices)</span>
        </div>

        <div className={classes.headerRow}>
          <div className={classes.chartContainer}>{nodewareAssetsCharts}</div>
          <div className={classes.chartContainer}>{nodewareAssetsByRiskChart}</div>
        </div>
        <div className={classes.boxBorder}>
          <Table<NodewareAsset, keyof NodewareAsset>
            sorter={sorter}
            columns={columns}
            onChange={(pagination, sorting) => {
              setSorter(sorting);
            }}
            rowContentCentered
            className={classes.table}
            rowKey={(row) => row.uuid}
            data={sortedNodewareAssets}
            loading={isLoadingNodeware}
          />
        </div>
      </div>*/}

      <div className={classes.section}>
        <div className={classes.summary} id='summary'>
          <ScoreSecurityCard
            className={classes.securityCard}
            title='Devices'
            skipGrading={deepScanTargets?.length || 0}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title='Critical Vulnerabilities'
            skipGrading={(criticalVulnerabilities || 0).toLocaleString()}
            score={criticalVulnerabilities > 0 ? 50 : (reportData?.networkSecurity.score || 0) * 100}
          />
          {/*<div className={classes.item}>
            <div className={classes.number}>{(deepScanTargets || []).length.toLocaleString()}</div>
            <div className={classes.description}>Total Assets Scanned</div>
          </div>
          <div className={classes.item}>
            <div className={cx(classes.number, classes.red)}>
              {(deepScanTargets || [])
                .map((t) => t.nmapScanStats?.cveCounts?.high || 0)
                .reduce((a, b) => a + b, 0)
                .toLocaleString()}
            </div>
            <div className={classes.description}>Critical Internal Vulnerabilities</div>
          </div>*/}
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Internal Vulnerabilities
          <span className={classes.findings}> ({sortedDeepScanTargets?.length} devices)</span>
        </div>

        <div className={classes.headerRow}>
          <div className={classes.chartContainer}>{internalVulnerabilitiesChart}</div>
          <div className={classes.chartContainer}>{targetsByInternalVulnerabilitiesRiskChart}</div>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.filterBar}>
          <div className={classes.title}>
            Trends
            <br />
            <span className={classes.redItalic}> Number of Critical Vulnerabilities</span>
          </div>
          <div>
            <SingleFilter
              buttonText={`Assets ${selectedAssets.length > 0 ? `(${selectedAssets.length})` : ''}`}
              defaultSelectedOptions={selectedAssets}
              popperText='Filter Assets'
              showDropdownIcon
              options={allAssets}
              setSelectedOptions={(a) => {
                setSelectedAssets(a);
              }}
            />
            <SingleFilter
              type='radio'
              buttonText={selectedLimit}
              defaultSelectedOptions={[selectedLimit]}
              popperText='Select Period'
              showDropdownIcon
              options={Object.keys(LIMITS)}
              setSelectedOptions={(a) => {
                setSelectedLimit(a[0]);
              }}
              startIcon={<></>}
              className={classes.marginLeft}
            />
          </div>
        </div>
        <div className={classes.chartContainer}>{networkSecurityChart}</div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Vulnerabilities by Devices
          <span className={classes.findings}> ({sortedDeepScanTargets?.length} devices)</span>
        </div>
        <div className={classes.boxBorder}>
          <Table<DeepScanTargetDTO, string>
            sorter={targetSorter}
            columns={targetColumns}
            onChange={(pagination, sorting) => {
              setTargetSorter(sorting);
            }}
            rowContentCentered
            className={classes.table}
            rowKey={(row) => row.id}
            data={sortedDeepScanTargets}
            loading={isTargetsLoading}
          />
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Vulnerabilities by Applications
          <span className={classes.findings}> ({sortedInstalledApps?.length} applications)</span>
        </div>
        <div className={classes.boxBorder}>
          <Table<InstalledAppsStats, string>
            sorter={installedAppsSorter}
            columns={installedAppsColumns}
            onChange={(pagination, sorting) => {
              if (pagination?.page !== undefined) {
                setInstalledAppsPage(pagination?.page);
              }

              const isSorterChanging = installedAppsSorter?.key !== sorting?.key || sorting?.order !== sorting?.order;
              setInstalledAppsSorter(sorting);

              if (isSorterChanging && pagination?.page !== 0) {
                setInstalledAppsPage(0);
              }
            }}
            rowContentCentered
            className={classes.table}
            rowKey={(row) => row.data.id}
            data={installedAppsPageData}
            loading={isInstalledAppsLoading}
          />
        </div>
      </div>

      <div className={classes.section} id='cves-table'>
        <div className={classes.title}>
          Vulnerabilities by CVEs
          <span className={classes.findings}>
            {' '}
            ({sortedDeepScanInternalVulnerabilities.length} CVEs
            {selectedTarget ? ` for Device ${selectedTarget.hostname}` : ''}
            {selectedInstalledApp
              ? ` for Application ${selectedInstalledApp.data.name} ${selectedInstalledApp.data.version}`
              : ''}
            )
            {(selectedTarget || selectedInstalledApp) && (
              <Button
                variant='outlined'
                className={classes.marginLeft}
                onClick={() => {
                  setSelectedTarget(null);
                  setSelectedInstalledApp(null);
                }}
              >
                Show All
              </Button>
            )}
          </span>
        </div>
        <div className={classes.boxBorderPadding}>
          <div className={classes.row}>
            <div className={classes.input}>
              <CustomTextField
                placeholder='Search for CVEs'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setCvesPage(0);
                }}
              />
            </div>
            <RadioSelect<SEVERITY | null>
              selectedValue={severity}
              options={[
                { label: 'All', value: null },
                { label: 'High', value: SEVERITY.HIGH },
                { label: 'Medium', value: SEVERITY.MEDIUM },
                { label: 'Low', value: SEVERITY.LOW },
                { label: 'Info', value: SEVERITY.INFO },
              ]}
              onOptionSelect={(s) => {
                setSeverity(s.value);
                setCvesPage(0);
              }}
            />
          </div>

          <Table<TopNmapVulnerability, string>
            pagination={
              isVulnerabilitiesLoading
                ? undefined
                : { page: cvesPage, perPage: PER_PAGE, total: (sortedDeepScanInternalVulnerabilities || []).length }
            }
            onRowClick={(row) => setSelectedVulnerability(row.data)}
            sorter={cvesSorter}
            columns={cvesColumns}
            onChange={(pagination, sorting) => {
              if (pagination?.page !== undefined) {
                setCvesPage(pagination?.page);
              }

              const isSorterChanging = cvesSorter?.key !== sorting?.key || sorting?.order !== sorting?.order;
              setCvesSorter(sorting);

              if (isSorterChanging && pagination?.page !== 0) {
                setCvesPage(0);
              }
            }}
            rowContentCentered
            className={classes.table}
            rowKey={(row) => row.data.id}
            data={cvesPageData}
            loading={isVulnerabilitiesLoading}
          />

          <NmapVulnerabilityDetailsModal
            handleClose={() => setSelectedVulnerability(null)}
            data={selectedVulnerability || undefined}
            isOpen={Boolean(selectedVulnerability)}
          />
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          External Vulnerabilities
          <span className={classes.findings}>
            {' '}
            (
            {
              findingsByType[SecurityScanType.PATCHING_CADENCE].filter(
                (f) => f.count > 0 && f.severity !== SecuritySeverity.INFO,
              ).length
            }{' '}
            findings)
            {isLoadingPatchingCadence ? '(External scan ongoing)' : ''}
          </span>
        </div>
        <div className={classes.boxBorder}>
          {(!isErrorPatchingCadence || securityScan.previousScan?.patchingCadenceScannedAt) && (
            <div className={classes.scanResultsWrapper}>
              {renderScanResults(
                findingsByType[SecurityScanType.PATCHING_CADENCE] || [],
                getFindingUrl,
                true,
                false,
                previousFindingsByType[SecurityScanType.PATCHING_CADENCE] || undefined,
              )}
            </div>
          )}
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Open Ports and Network Vulnerabilities
          <span className={classes.findings}>
            {' '}
            ({findingsByType[SecurityScanType.NETWORK_SECURITY].filter((f) => f.count > 0).length} findings)
            {isLoadingScanNetwork ? '(External scan ongoing)' : ''}
          </span>
        </div>
        <div className={classes.boxBorder}>
          {(!isErrorScanNetwork || securityScan.previousScan?.networkScannedAt) && (
            <div className={classes.scanResultsWrapper}>
              {renderScanResults(
                findingsByType[SecurityScanType.NETWORK_SECURITY] || [],
                getFindingUrl,
                true,
                false,
                previousFindingsByType[SecurityScanType.NETWORK_SECURITY] || undefined,
              )}
            </div>
          )}
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          IP Reputation
          <span className={classes.findings}>
            {' '}
            ({findingsByType[SecurityScanType.IP_REPUTATION].filter((f) => f.count > 0).length} findings)
            {isLoadingScanIpReputation ? '(External scan ongoing)' : ''}
          </span>
        </div>
        <div className={classes.boxBorder}>
          {(!isErrorScanIpReputation || securityScan.previousScan?.ipReputationScannedAt) && (
            <div className={classes.scanResultsWrapper}>
              {renderScanResults(
                findingsByType[SecurityScanType.IP_REPUTATION] || [],
                getFindingUrl,
                true,
                false,
                previousFindingsByType[SecurityScanType.IP_REPUTATION] || undefined,
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
