import { makeStyles } from '@material-ui/core/styles';
import { LoadingContainer } from 'components/LoadingContainer';
import { AgentDTO } from 'dtos/agent';
import { useRiskAssessmentPlusEnabled } from 'hooks/useRiskAssessmentPlusEnabled';
import { useCurrentAgencyStats } from 'queries/useAgency';
import React from 'react';
import { COLORS } from 'telivy-theme';

import { AgencyData } from './AgencyData';
import { AgencyLogo } from './AgencyLogo';
import { StatBox } from './StatBox';

const useStyles = makeStyles((theme) => ({
  root: {},
  stats: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  stat: {
    maxWidth: '50%',
    minWidth: 300,
  },
  color: {
    color: COLORS.BLUE_1,
  },
}));

interface Props {
  agent: AgentDTO;
}

export const ManageView: React.FC<Props> = ({ agent }) => {
  const classes = useStyles();
  const { data, isLoading } = useCurrentAgencyStats();
  const canChooseRiskPlus = useRiskAssessmentPlusEnabled();

  return (
    <div className={classes.root}>
      <AgencyData agent={agent} />
      <AgencyLogo agent={agent} />

      <h3>Total Usage</h3>

      <div className={classes.stats}>
        {isLoading ? (
          <LoadingContainer />
        ) : (
          <>
            <StatBox
              className={classes.stat}
              count={data?.totalActiveLightScanAssetsCount || 0}
              label='Risk Assesment Assets'
            />
            {canChooseRiskPlus && (
              <StatBox
                color
                className={classes.stat}
                count={data?.totalActiveDeepScanAssets || 0}
                label={
                  <div>
                    Risk Assessment <span className={classes.color}>Plus</span> Assets
                  </div>
                }
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
