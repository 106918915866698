import { makeStyles } from '@material-ui/core';
import { Option, RadioSelect } from 'components/RadioSelect';
import { ApplicationStatus, ApplicationWithAgentDTO } from 'dtos/application';
import { CompanyDTO } from 'dtos/company';
import { DeepScanDTO, DeepScanTargetDTO } from 'dtos/deep-scan';
import React from 'react';
// import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createSecurityRoute } from 'telivy-constants';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { Devices } from '../assets/targets/Devices';
import { InstallAgentsSection } from './InstallAgentsSection';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    gap: theme.spacing(2),
  },
  table: {
    width: '100%',
  },

  selectsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  select: {},
}));

export type SourceRoute = {
  PARENT: string;
  ROOT: string;
  deploy: {
    PARENT: string;
    ROOT: string;
    INSTRUCTIONS: string;
    DEVICES: string;
  };
};

interface Props {
  application: ApplicationWithAgentDTO;
  sourceRoute: SourceRoute;
  deepScan?: DeepScanDTO;
  company?: CompanyDTO;
  deepScanTargets?: DeepScanTargetDTO[];
  isAdmin: boolean;
  enableCharts: boolean;
}

export const ApplicationSetupDeployView = ({
  application,
  sourceRoute,
  deepScan,
  company,
  deepScanTargets,
  isAdmin,
  enableCharts,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const viewOptions: Option<ValueOf<SourceRoute['deploy']>>[] = React.useMemo(() => {
    const options = [];

    options.push({
      label: `Instructions`,
      value: createSecurityRoute(sourceRoute.deploy.INSTRUCTIONS, application.id),
    });

    options.push({
      label: `Deployment Status`,
      value: createSecurityRoute(sourceRoute.deploy.DEVICES, application.id),
    });

    return options;
  }, [sourceRoute, application]);

  const rootRoute: string = React.useMemo(() => {
    if (
      [
        ApplicationStatus.NOT_STARTED,
        ApplicationStatus.CONFIGURATION_PENDING,
        ApplicationStatus.CONFIGURATION_DONE,
        ApplicationStatus.DATA_PENDING,
      ].includes(application?.applicationStatus || ApplicationStatus.NOT_STARTED)
    ) {
      return createSecurityRoute(sourceRoute.deploy.INSTRUCTIONS, application.id);
    } else {
      return createSecurityRoute(sourceRoute.deploy.DEVICES, application.id);
    }
  }, [application, sourceRoute]);

  return (
    <ApplicationViewContainer>
      <div className={classes.selectsContainer}>
        <RadioSelect<ValueOf<SourceRoute['deploy']>>
          responsive
          options={viewOptions}
          className={classes.select}
          selectedValue={location.pathname}
          onOptionSelect={(opt) => navigate(opt.value)}
          selectedFn={(optionValue, selectedValue) => (selectedValue ? selectedValue.includes(optionValue) : false)}
        />
      </div>

      <Routes>
        <Route
          path={sourceRoute.deploy.INSTRUCTIONS}
          element={deepScan && <InstallAgentsSection deepScan={deepScan} application={application} company={company} />}
        />

        <Route
          path={sourceRoute.deploy.DEVICES}
          element={
            deepScanTargets &&
            deepScan && (
              <Devices
                deepScanTargets={deepScanTargets}
                deepScanId={deepScan.id}
                application={application}
                isAdmin={isAdmin}
                enableCharts={enableCharts}
              />
            )
          }
        />

        <Route index element={<Navigate replace to={rootRoute} />} />
      </Routes>
    </ApplicationViewContainer>
  );
};
