import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RemoveFromQueueRoundedIcon from '@material-ui/icons/RemoveFromQueueRounded';
import { DeepScanTargetDTO } from 'dtos/deep-scan';
import { useToasters } from 'hooks/useToasters';
import { useUninstallDeepScanTarget } from 'queries/useDeepScan';
import React, { useCallback } from 'react';

interface Props {
  deepScanTarget: DeepScanTargetDTO;
  className?: string;
}

export const UninstallButton: React.FC<Props> = ({ deepScanTarget, className }) => {
  const [open, setOpen] = React.useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();
  const { mutate, isLoading } = useUninstallDeepScanTarget(deepScanTarget.deepScanId, deepScanTarget.id, {
    onSuccess: () => {
      setOpen(false);
      showToaster('Uninstall scheduled successfully');
    },
    onError: (error) => {
      setOpen(false);
      toasterErrorHandler(error, 'Failed to schedule uninstall');
    },
  });

  const handleConfirmClick = useCallback(() => {
    mutate();
  }, [mutate]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!isLoading) {
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        variant='outlined'
        color='secondary'
        className={className}
        onClick={handleClickOpen}
        title='Uninstall'
        // startIcon={<RemoveFromQueueRoundedIcon />}
      >
        <RemoveFromQueueRoundedIcon />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Uninstallation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to schedule uninstall for device &quot;{deepScanTarget.hostname}&quot;?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmClick}
            variant='contained'
            color='secondary'
            disabled={isLoading}
            startIcon={<RemoveFromQueueRoundedIcon />}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
