import { makeStyles } from '@material-ui/core';
import { Option, RadioSelect } from 'components/RadioSelect';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { DeepScanDTO } from 'dtos/deep-scan';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createSecurityRoute } from 'telivy-constants';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { ConnectNodeware } from './ConnectNodeware';
import { UninstallAgentsSection } from './UninstallAgentsSection';
import { UploadEmployeeListSection } from './UploadEmployeeListSection';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    gap: theme.spacing(2),
  },
  table: {
    width: '100%',
  },

  selectsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  select: {},
}));

export type SourceRoute = {
  PARENT: string;
  ROOT: string;
  // DEPLOY: string;
  CLOUD: string;
  optional: {
    PARENT: string;
    ROOT: string;
    EMAIL_LIST: string;
    NODEWARE: string;
    REMOVE: string;
  };
};

interface Props {
  application: ApplicationWithAgentDTO;
  sourceRoute: SourceRoute;
  deepScan?: DeepScanDTO;
  isNodewareEnabled?: boolean;
}

export const ApplicationSetupOptionalView = ({ application, sourceRoute, deepScan, isNodewareEnabled }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const viewOptions: Option<ValueOf<SourceRoute['optional']>>[] = React.useMemo(() => {
    const options = [];

    options.push({
      label: `Dark Web Email List`,
      value: createSecurityRoute(sourceRoute.optional.EMAIL_LIST, application.id),
    });

    if (isNodewareEnabled) {
      options.push({
        label: `Connect Nodeware`,
        value: createSecurityRoute(sourceRoute.optional.NODEWARE, application.id),
      });
    }

    options.push({
      label: `Remove Telivy`,
      value: createSecurityRoute(sourceRoute.optional.REMOVE, application.id),
    });

    return options;
  }, [sourceRoute, application, isNodewareEnabled]);

  return (
    <ApplicationViewContainer>
      <Helmet>
        <title>Setup - {application?.applicationResponse?.organization_name}</title>
      </Helmet>

      <div className={classes.selectsContainer}>
        <RadioSelect<ValueOf<SourceRoute['optional']>>
          responsive
          options={viewOptions}
          className={classes.select}
          selectedValue={location.pathname}
          onOptionSelect={(opt) => navigate(opt.value)}
          selectedFn={(optionValue, selectedValue) => (selectedValue ? selectedValue.includes(optionValue) : false)}
        />
      </div>

      <Routes>
        <Route
          path={sourceRoute.optional.EMAIL_LIST}
          element={deepScan && <UploadEmployeeListSection deepScan={deepScan} />}
        />

        <Route
          path={sourceRoute.optional.NODEWARE}
          element={deepScan && <ConnectNodeware application={application} deepScan={deepScan} />}
        />

        <Route
          path={sourceRoute.optional.REMOVE}
          element={deepScan && <UninstallAgentsSection deepScan={deepScan} application={application} />}
        />

        <Route index element={<Navigate replace to={sourceRoute.optional.EMAIL_LIST} />} />
      </Routes>
    </ApplicationViewContainer>
  );
};
