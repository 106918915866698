// import { makeStyles } from '@material-ui/core';
// import { Option, RadioSelect } from 'components/RadioSelect';
import { AgentDTO } from 'dtos/agent';
import { ApplicationWithAgentDTO } from 'dtos/application';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router-dom';
// import { createSecurityRoute } from 'telivy-constants';
import { isAgentAnAdmin } from 'telivy-selectors';
import { AlertsHistoryView } from 'views/agent/views/alerts/History';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
// import { AlertsOverviewView } from './Overview';
import { ApplicationMonitoringView } from '../monitoring';
import { AlertsPreferencesView } from './Preferences';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     flexDirection: 'column',
//     position: 'relative',
//     width: '100%',
//     gap: theme.spacing(2),
//   },
//   table: {
//     width: '100%',
//   },

//   selectsContainer: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     marginBottom: theme.spacing(2),
//   },
//   select: {},
// }));

export type SourceRoute = {
  security: {
    OVERVIEW: string;
    TARGETS: string;
    EXTERNAL_SCAN: string;
    FINDINGS: string;
    FINDING: string;
    BROWSER_PASSWORDS: string;
    TECHSTACK: string;
    PASSWORDS: string;
    VULNERABILITIES: string;
    COMPANY_INFO: string;
    DOWNLOAD: string;
  };
  targets: {
    PARENT: string;
    ROOT: string;
    DOMAINS: string;
    DEVICES: string;
  };
  alerts: {
    PARENT: string;
    ROOT: string;
    OVERVIEW: string;
    POLICIES: string;
    MONITORING: string;
  };
};

interface Props {
  application: ApplicationWithAgentDTO;
  sourceRoute: SourceRoute;
  agent: AgentDTO;
}

export const ApplicationAlertsView = ({ application, sourceRoute, agent }: Props) => {
  // const classes = useStyles();
  // const navigate = useNavigate();
  // const location = useLocation();

  // const viewOptions: Option<ValueOf<SourceRoute['alerts']>>[] = React.useMemo(() => {
  //   const options = [];

  //   options.push({
  //     label: `All Alerts`,
  //     value: createSecurityRoute(sourceRoute.alerts.OVERVIEW, application.id),
  //   });

  //   // options.push({
  //   //   label: `Policies`,
  //   //   value: createSecurityRoute(sourceRoute.alerts.POLICIES, application.id),
  //   // });

  //   return options;
  // }, [sourceRoute, application]);

  const isAdmin = isAgentAnAdmin(agent);

  return (
    <ApplicationViewContainer>
      <Helmet>
        <title>Alerts - {application?.applicationResponse?.organization_name}</title>
      </Helmet>

      {/*<div className={classes.selectsContainer}>
        <RadioSelect<ValueOf<SourceRoute['alerts']>>
          responsive
          options={viewOptions}
          className={classes.select}
          selectedValue={location.pathname}
          onOptionSelect={(opt) => navigate(opt.value)}
          selectedFn={(optionValue, selectedValue) => (selectedValue ? selectedValue.includes(optionValue) : false)}
        />
      </div>*/}

      <Routes>
        {application.securityScan && (
          <Route
            path={sourceRoute.alerts.OVERVIEW}
            element={<AlertsHistoryView applicationId={application.id} agent={agent} />}
          />
        )}

        <Route path={sourceRoute.alerts.POLICIES} element={<AlertsPreferencesView application={application} />} />

        <Route
          path={sourceRoute.alerts.MONITORING}
          element={<ApplicationMonitoringView application={application} isAdmin={isAdmin} />}
        />

        <Route index element={<Navigate replace to={sourceRoute.alerts.OVERVIEW} />} />
      </Routes>
    </ApplicationViewContainer>
  );
};
