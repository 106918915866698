import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import { DeepScanDTO } from 'dtos/deep-scan';
import { useToasters } from 'hooks/useToasters';
import { ReactComponent as VerifyIcon } from 'images/verify.svg';
import { startCase } from 'lodash';
import { useUpdateDeepScan } from 'queries/useDeepScan';
import React, { useMemo } from 'react';
import { COLORS } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

import { CONFIGURATION_STEP } from './configuration';

interface StylesProps {
  isVerified: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    margin: 0,
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: 15,
    margin: 0,
    maxWidth: 1200,
  },
  content: {
    marginTop: theme.spacing(3),
    maxWidth: 1200,
  },
  headline: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  headMenu: {},
  verifyButton: {
    background: () => {
      return COLORS.GREEN_1;
    },
  },

  icon: {
    marginRight: theme.spacing(1),
  },

  badge: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  type: CONFIGURATION_STEP;
  title: string;
  deepScan: DeepScanDTO;
  description: string;
  hideVerify?: boolean;
  onVerify?: () => void;
  children: React.ReactNode;
  button?: { text?: string; props?: ButtonProps };
}

export const SectionTemplate = ({
  type,
  button,
  hideVerify,
  deepScan,
  // title,
  description,
  children,
  onVerify,
}: Props) => {
  const isVerified = useMemo(() => {
    switch (type) {
      case CONFIGURATION_STEP.INSTALLATION:
        return deepScan.installedAgents;
      case CONFIGURATION_STEP.EMPLOYEES_LIST:
        return deepScan.uploadedEmployeeList;
      case CONFIGURATION_STEP.PHISHING:
        return deepScan.configuredPhishing;
      case CONFIGURATION_STEP.SAAS_ALERTS:
        return deepScan.completedSaasAlerts;
      case CONFIGURATION_STEP.CLOUD_SOFTWARES:
        return deepScan.m365Connected;
      default:
        return false;
    }
  }, [deepScan, type]);

  const isAllCompleted = useMemo(() => {
    const hasCompletedSaasAlerts = deepScan.saasAlertLink ? deepScan.completedSaasAlerts : true;

    return (
      deepScan.installedAgents && deepScan.uploadedEmployeeList && deepScan.configuredPhishing && hasCompletedSaasAlerts
    );
  }, [deepScan]);

  const classes = useStyles({ isVerified });
  const { showToaster } = useToasters();
  const { mutate, isLoading } = useUpdateDeepScan(deepScan.id, undefined, {
    onSuccess: () => {
      showToaster(`Successfully verified ${startCase(type)}!`);
      onVerify && onVerify();
    },
  });

  const onVerifyCompletion = () => {
    switch (type) {
      case CONFIGURATION_STEP.INSTALLATION:
        mutate({ installedAgents: true });
        break;
      case CONFIGURATION_STEP.EMPLOYEES_LIST:
        mutate({ uploadedEmployeeList: true });
        break;
      case CONFIGURATION_STEP.PHISHING:
        mutate({ configuredPhishing: true });
        break;
      case CONFIGURATION_STEP.SAAS_ALERTS:
        mutate({ completedSaasAlerts: true });
        break;
      default:
        break;
    }
  };

  const buttonLabel = useMemo(() => {
    if (button && button.text) {
      return button.text;
    }

    return isLoading ? 'Verifying...' : button?.text || isVerified ? 'Verified' : 'Verify';
  }, [isLoading, isVerified, button]);

  return (
    <div>
      {isAllCompleted && (
        <SecurityCard.Badge variant='info' className={classes.badge}>
          Telivy is currently performing a full assessment, and we will reach out to you soon.
        </SecurityCard.Badge>
      )}

      <div className={classes.headline}>
        {/*<h1 className={classes.title}>{title}</h1>*/}

        {!hideVerify && (
          <div className={classes.headMenu}>
            <Button
              disabled={isVerified}
              onClick={isVerified ? undefined : onVerifyCompletion}
              variant='contained'
              color='primary'
              className={classes.verifyButton}
              {...button?.props}
            >
              <VerifyIcon className={classes.icon} /> {buttonLabel}
            </Button>
          </div>
        )}
      </div>

      <p className={classes.description}>{description}</p>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
