import { useAuth0 } from '@auth0/auth0-react';
import { Hidden, Menu, MenuItem } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { useCurrentAgent } from 'queries/useAgent';
import React, { useCallback, useMemo } from 'react';
import { clearAuthTokens } from 'services/auth0-tokens';
import { isAgentAnAdmin } from 'telivy-selectors';
// import { isAgentAnAdmin } from 'telivy-selectors';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {},
  button: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    cursor: 'pointer',
    padding: `0 ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(1),
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    border: 0,
    width: '100%', // some width

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },

  avatar: {
    marginRight: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },

    height: theme.spacing(4),
  },

  username: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
  },
}));

interface Props {
  className?: string;
}

export const UserStatus = ({ className }: Props) => {
  const classes = useStyles();
  const { data } = useCurrentAgent();
  const { logout, isAuthenticated, isLoading } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const username = useMemo(() => {
    if (!data) {
      return null;
    }

    let username = '';

    if (data.firstName) {
      username += `${data.firstName} `;
    }

    if (data.lastName) {
      username += `${data.lastName}`;
    }

    // If no first name or last name, use email
    if (!username) {
      username = data.email;
    }

    return username.trim();
  }, [data]);

  const handleLogout = useCallback(() => {
    clearAuthTokens(true);
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!isAuthenticated) {
    return null;
  }

  const currentLogo = !data?.agency?.isMasterAgency && data?.agency?.logoUrl;
  const enableCharts = data?.agency?.enableCharts;

  return (
    <div className={className}>
      <div className={classes.buttonsContainer}>
        <button onClick={handleClick} className={classes.button}>
          {enableCharts && currentLogo && <img src={currentLogo} alt='agency-logo' className={classes.avatar} />}
          {!(enableCharts && currentLogo) && <AccountCircleRoundedIcon className={classes.avatar} />}
          <Hidden xsDown>
            <span className={classes.username}>
              {username} {isAgentAnAdmin(data) ? ' (admin)' : ''}
            </span>
          </Hidden>
        </button>
      </div>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {data && <MenuItem disabled>Welcome: {data.email}</MenuItem>}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};
