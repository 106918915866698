import { makeStyles } from '@material-ui/core/styles';
// import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import cx from 'classnames';
import { Badge } from 'components/Badge';
import { EmptyState } from 'components/EmptyState';
import { LoadingContainer } from 'components/LoadingContainer';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import dayjs from 'dayjs';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { DeepScanGwsActivityLogin, DeepScanGwsDomainUsers } from 'dtos/deep-scan';
import { useGwsLoginMapChart } from 'hooks/charts/useGwsLoginMapChart';
import { useChartImage } from 'hooks/useChartImage';
import { useChartsEnabled } from 'hooks/useChartsEnabled';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { useApplicationSecurityChartsUrls } from 'queries/useCharts';
import { useDeepScan, useDeepScanGoogleWsSecurityData } from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { ApplicationViewContainer } from 'views/agent/views/application-details/views/ApplicationViewContainer';

import { ScoreSecurityCard } from '../../components/common/ScoreSecurityCard';
import { ScoreBox, ScoreRanking } from '../../components/ScoreBox';
import { SecurityCard } from '../../components/SecurityCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  findings: {
    color: COLORS.GREY_3,
  },

  section: {
    marginBottom: theme.spacing(4),
  },

  scanResultsWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(1.25),
    },
  },

  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  boxBorder: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
  },

  mapContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },

  mapContainerItem: {
    flex: 1,
    flexDirection: 'column',
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    '&:last-child': {
      paddingTop: 0,
    },
  },

  summary: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(2),
  },
  item: {
    border: `solid 1px ${COLORS.GREY_5}`,
    backgroundColor: 'rgba(249, 251, 252, 0.5)',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  description: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_2,
    marginTop: theme.spacing(1),
  },
  number: {
    ...TYPOGRAPHY.TITLE_2,
    fontSize: 48,
    color: COLORS.TEXT,
  },
  red: {
    color: COLORS.RED_1,
  },
  green: {
    color: COLORS.GREEN_1,
  },
  blue: {
    color: COLORS.BLUE_1,
  },

  chart: {
    // maxHeight: 600,
  },
  chartContainer: {
    marginBottom: theme.spacing(2),
  },
  disclaimer: {
    width: 700,
    margin: '0 auto',
  },
  errorBox: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'start',
  },

  legend: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },

  noBorder: {
    border: 'none',
  },
  securityCard: {
    flex: 1,
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  isAdmin: boolean;
}

// const STRONG_THRESHOLD = 80;
// const MODERATE_THRESHOLD = 40;
const PER_PAGE = 8;
const PER_PAGE_LOCATIONS = 10;

// const getScoreBadgeVariant = (scoreInPercentage: number | null): BadgeVariant => {
//   if (scoreInPercentage && scoreInPercentage > STRONG_THRESHOLD) {
//     return 'green';
//   } else if (scoreInPercentage && scoreInPercentage > MODERATE_THRESHOLD) {
//     return 'yellow';
//   } else {
//     return 'red';
//   }
// };

const getMFAScoreRanking = (mfaStatus: string): ScoreRanking => {
  switch (mfaStatus) {
    case 'Enabled':
      return 'Low';
    case 'Not enabled':
      return 'High';
    default:
      return 'None';
  }
};

export interface DeepScanGwsUserRow {
  id: string;
  fullName: string;
  primaryEmail: string;
  status: string;
  mfaStatus: number;
  lastLogin?: Date;
  successes?: number;
  failures?: number;
}

interface GwsLoginByLocationData {
  location: string;
  successful: number;
  failed: number;
}

export const DeepScanGoogleWsSecurity = ({ application, isAdmin }: Props) => {
  const classes = useStyles();
  const deepScanId = application.deepScanId || '';
  const { data: usersRaw, isLoading: isLoadingUsers } = useDeepScanGoogleWsSecurityData<DeepScanGwsDomainUsers>(
    deepScanId,
    'users',
    { enabled: Boolean(deepScanId) },
  );
  const { data: auditLogsSignIns, isLoading: isLoadingAuditLogsSignIns } = useDeepScanGoogleWsSecurityData<
    DeepScanGwsActivityLogin[]
  >(deepScanId, 'activityLogins', {
    enabled: Boolean(deepScanId),
  });

  const { data: deepScan } = useDeepScan(deepScanId);
  const enableCharts = useChartsEnabled(deepScan, application);

  const [userPage, setUserPage] = useState(0);
  const [userSorter, setUserSorter] = useState<Sorter<keyof DeepScanGwsUserRow> | undefined>({
    key: 'fullName',
    order: SortOrder.ASC,
  });

  const [locationPage, setLocationPage] = useState(0);
  const [locationSorter, setLocationSorter] = useState<Sorter<keyof GwsLoginByLocationData> | undefined>({
    key: 'successful',
    order: SortOrder.DESC,
  });

  const { data: charts } = useApplicationSecurityChartsUrls(application.id);
  // const mapChart = useChartImage(
  //   { chartContainerClassName: classes.noBorder },
  //   charts?.gwsLoginStatusMap,
  //   deepScan,
  //   application,
  // );
  const failedLoginsChart = useChartImage(
    { title: 'Failed Logins by User', className: classes.chart, chartContainerClassName: classes.chartContainer },
    charts?.gwsFailedLogins,
    deepScan,
    application,
  );
  const loginActivityChart = useChartImage(
    { title: 'Login Activity', className: classes.chart, chartContainerClassName: classes.chartContainer },
    charts?.gwsLoginActivity,
    deepScan,
    application,
  );

  const users = useMemo(() => usersRaw?.users || [], [usersRaw]);
  const loginAudits = useMemo(() => auditLogsSignIns || [], [auditLogsSignIns]);

  const { chart: mapChart } = useGwsLoginMapChart(loginAudits);

  const loginByLocation = useMemo(() => {
    const res: Record<string, DeepScanGwsActivityLogin[]> = {};
    if (auditLogsSignIns) {
      for (const al of auditLogsSignIns || []) {
        let location = '';
        if (al.location) {
          location = [al.location.city, al.location.state, al.location.countryOrRegion].join(', ');
        }

        res[location] = res[location] || [];
        res[location].push(al);
      }
    }
    return res;
  }, [auditLogsSignIns]);

  const userColumns = useMemo((): Column<DeepScanGwsUserRow, keyof DeepScanGwsUserRow>[] => {
    return [
      {
        title: 'Name',
        sortKey: 'fullName',
        render: (row) => row.fullName,
      },
      {
        title: 'Email',
        sortKey: 'primaryEmail',
        render: (row) => row.primaryEmail,
      },
      {
        title: 'Sign-in Status',
        sortKey: 'status',
        render: (row) => (
          <ScoreBox
            ranking={getMFAScoreRanking(
              row.status == 'Suspended' || row.status == 'Archived' ? 'Not Enabled' : 'Enabled',
            )}
            label={row.status}
          />
        ),
      },
      {
        title: 'MFA Status',
        sortKey: 'mfaStatus',
        render: (row) => {
          const mfaStatus = row.mfaStatus > 1 ? 'Enabled' : 'Not enabled';
          return <ScoreBox ranking={getMFAScoreRanking(mfaStatus)} label={mfaStatus} />;
        },
      },
      {
        title: 'Last Login',
        sortKey: 'lastLogin',
        render: (row) => (row.lastLogin ? dayjs(row.lastLogin).format('M/D/YYYY h:mm A') : 'N/A'),
      },
      {
        title: 'Successful Logins',
        sortKey: 'successes',
        render: (row) => row.successes,
      },
      {
        title: 'Login Failures',
        sortKey: 'failures',
        render: (row) => row.failures,
      },
    ];
  }, []);

  const sortedUsers = useMemo(() => {
    const filteredUsers = users.filter((u) => u.primaryEmail);

    if (userSorter) {
      const data = sortBy(filteredUsers, userSorter.key);

      if (userSorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(filteredUsers, (user) => user.name.fullName);
  }, [users, userSorter]);

  const usersData: DeepScanGwsUserRow[] = useMemo(() => {
    return (sortedUsers || [])
      .map((row) => {
        return {
          id: row.id,
          fullName: row.name.fullName,
          primaryEmail: row.primaryEmail,
          status: row.suspended ? 'Suspended' : row.archived ? 'Archived' : 'Active',
          mfaStatus: row.isEnrolledIn2Sv ? 2 : 1,
          lastLogin: row.lastLoginTime,
          successes: (loginAudits || []).filter(
            (l) =>
              row.id === l.actor.profileId &&
              l.events.filter((e) => e.name === 'login_success').length > 0 &&
              l.events.filter((e) => e.name === 'login_failure').length === 0,
          ).length,
          failures: (loginAudits || []).filter(
            (l) => row.id === l.actor.profileId && l.events.filter((e) => e.name === 'login_failure').length > 0,
          ).length,
        };
      })
      .slice(userPage * PER_PAGE, userPage * PER_PAGE + PER_PAGE);
  }, [userPage, sortedUsers, loginAudits]);

  const orphanedAccounts = useMemo(() => {
    return (usersData || []).filter((u) => moment().diff(moment(u.lastLogin), 'days') >= 30);
  }, [usersData]);

  const locationsFullData: GwsLoginByLocationData[] = useMemo(() => {
    return Object.keys(loginByLocation).map((location) => {
      return {
        location,
        successful: (loginByLocation[location] || []).filter(
          (l) =>
            l.events.filter((e) => e.name === 'login_success').length > 0 &&
            l.events.filter((e) => e.name === 'login_failure').length === 0,
        ).length,
        failed: (loginByLocation[location] || []).filter(
          (l) => l.events.filter((e) => e.name === 'login_failure').length > 0,
        ).length,
      };
    });
  }, [loginByLocation]);

  const locationColumns = useMemo((): Column<GwsLoginByLocationData, keyof GwsLoginByLocationData>[] => {
    return [
      {
        title: 'Location',
        sortKey: 'location',
        render: (row) => row.location,
      },
      {
        title: 'Successful logins',
        sortKey: 'successful',
        render: (row) => row.successful,
      },
      {
        title: 'Login Failures',
        sortKey: 'failed',
        render: (row) => row.failed,
      },
    ];
  }, []);

  const sortedLocations = useMemo(() => {
    if (locationSorter) {
      const data = sortBy(locationsFullData, locationSorter.key);

      if (locationSorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(locationsFullData, (l) => l.location);
  }, [locationsFullData, locationSorter]);

  const locationsData = useMemo(() => {
    return (sortedLocations || []).slice(
      locationPage * PER_PAGE_LOCATIONS,
      locationPage * PER_PAGE_LOCATIONS + PER_PAGE_LOCATIONS,
    );
  }, [locationPage, sortedLocations]);

  const accountNoMfa = useMemo(() => {
    return (usersData || []).filter((u) => u.mfaStatus <= 1);
  }, [usersData]);

  if (isLoadingUsers) {
    return <LoadingContainer />;
  }

  if (!enableCharts && !isAdmin) {
    return (
      <ApplicationViewContainer>
        <div className={classes.disclaimer}>
          <SecurityCard.Badge variant='locked'>
            Please upgrade account. Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
          </SecurityCard.Badge>
        </div>
      </ApplicationViewContainer>
    );
  }

  if (!usersRaw) {
    return (
      <ApplicationViewContainer>
        <EmptyState title='Google Workspace can take anywhere from 15 minutes to a few hours for all the data to be fetched. We will notify you via email when Google Workspace insights are ready.' />
      </ApplicationViewContainer>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>Google Workspace Security</div>

      <div className={classes.section}>
        {usersData.length > 0 && (
          <div className={classes.summary}>
            <ScoreSecurityCard className={classes.securityCard} title='Total Accounts' skipGrading={users?.length} />
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Accounts without MFA'
              skipGrading={accountNoMfa.length.toLocaleString()}
              score={accountNoMfa.length > 0 ? 50 : 100}
              // onClick={onClickNoMfaCount}
            />
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Total Failed Logins'
              skipGrading={loginAudits
                .filter((l) => l.events.filter((e) => e.name === 'login_failure').length > 0)
                .length.toLocaleString()}
              score={
                loginAudits.filter((l) => l.events.filter((e) => e.name === 'login_failure').length > 0).length > 0
                  ? 50
                  : 100
              }
            />
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Orphaned Accounts'
              skipGrading={(orphanedAccounts || []).length.toLocaleString()}
              score={(orphanedAccounts || []).length > 0 ? 50 : 100}
            />
            {/*<div className={classes.item}>
              <div className={cx(classes.number, classes.blue)}>{users?.length}</div>
              <div className={classes.description}>Total Accounts</div>
            </div>
            <div className={classes.item}>
              <div className={cx(classes.number, classes.red)}>
                {(users || []).filter((u) => !u.isEnrolledIn2Sv).length}
              </div>
              <div className={classes.description}>Accounts without MFA</div>
            </div>
            <div className={classes.item}>
              <div className={classes.number}>{(orphanedAccounts || []).length}</div>
              <div className={classes.description}>Orphaned Accounts</div>
            </div>*/}
          </div>
        )}
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Accounts Status
          <span className={classes.findings}> ({users.length} users)</span>
        </div>
        {usersRaw?.error && usersRaw?.error.indexOf('Not Authorized') > -1 && (
          <SecurityCard.Badge variant='error' className={classes.errorBox}>
            To get accounts data from Google Workspace, please connect using an admin account and ensure that Google
            Admin SDK API is enabled.
          </SecurityCard.Badge>
        )}
        {usersRaw?.error && isAdmin && (
          <SecurityCard.Badge variant='error' className={classes.errorBox}>
            [Admin] Error: {usersRaw?.error}
          </SecurityCard.Badge>
        )}
        {usersData.length > 0 && (
          <div className={classes.boxBorder}>
            <Table<DeepScanGwsUserRow, keyof DeepScanGwsUserRow>
              columns={userColumns}
              pagination={
                isLoadingUsers ? undefined : { page: userPage, perPage: PER_PAGE, total: sortedUsers?.length || 0 }
              }
              sorter={userSorter}
              onChange={(pagination, sorting) => {
                if (pagination?.page !== undefined) {
                  setUserPage(pagination?.page);
                }

                const isSorterChanging = userSorter?.key !== sorting?.key || sorting?.order !== sorting?.order;
                setUserSorter(sorting);

                if (isSorterChanging && pagination?.page !== 0) {
                  setUserPage(0);
                }
              }}
              rowContentCentered
              className={classes.table}
              rowKey={(row) => `${row.id}`}
              data={usersData}
              loading={isLoadingUsers}
            />
          </div>
        )}
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Login Status
          {/*<span className={classes.findings}> ({loginAudits.length} audit logs)</span>*/}
        </div>
        {loginAudits.length > 0 && (
          <>
            <div className={classes.summary}>
              <ScoreSecurityCard
                className={classes.securityCard}
                title='Total Login Events'
                skipGrading={(loginAudits || []).length.toLocaleString()}
                // score={(loginAudits || []).length > 0 ? 85 : 100}
              />

              <ScoreSecurityCard
                className={classes.securityCard}
                title='Successful Logins'
                skipGrading={loginAudits
                  .filter(
                    (l) =>
                      l.events.filter((e) => e.name === 'login_success').length > 0 &&
                      l.events.filter((e) => e.name === 'login_failure').length === 0,
                  )
                  .length.toLocaleString()}
                score={100}
              />

              <ScoreSecurityCard
                className={classes.securityCard}
                title='Failed Logins'
                skipGrading={loginAudits
                  .filter((l) => l.events.filter((e) => e.name === 'login_failure').length > 0)
                  .length.toLocaleString()}
                score={
                  loginAudits.filter((l) => l.events.filter((e) => e.name === 'login_failure').length > 0).length > 0
                    ? 50
                    : 100
                }
              />

              <ScoreSecurityCard
                className={classes.securityCard}
                title='Login Locations'
                skipGrading={sortedLocations.length.toLocaleString()}
                // score={85}
              />

              {/*<div className={classes.item}>
                <div className={classes.number}>{loginAudits.length}</div>
                <div className={classes.description}>Events</div>
              </div>

              <div className={classes.item}>
                <div className={cx(classes.number, classes.green)}>
                  {
                    loginAudits.filter(
                      (l) =>
                        l.events.filter((e) => e.name === 'login_success').length > 0 &&
                        l.events.filter((e) => e.name === 'login_failure').length === 0,
                    ).length
                  }
                </div>
                <div className={classes.description}>Successful Logins</div>
              </div>

              <div className={classes.item}>
                <div className={cx(classes.number, classes.red)}>
                  {loginAudits.filter((l) => l.events.filter((e) => e.name === 'login_failure').length > 0).length}
                </div>
                <div className={classes.description}>Failed Logins</div>
              </div>

              <div className={classes.item}>
                <div className={classes.number}>{sortedLocations.length}</div>
                <div className={classes.description}>Login Locations</div>
              </div>*/}
            </div>

            <div className={cx(classes.mapContainer, classes.section)}>
              <div className={classes.mapContainerItem}>
                {mapChart}
                <div className={classes.legend}>
                  <strong>Legend: </strong>
                  <Badge variant='green'>Low Severity</Badge>
                  <Badge variant='yellow'>Medium Severity</Badge>
                  <Badge variant='red'>High Severity</Badge>
                </div>
              </div>
              <div className={classes.mapContainerItem}>
                <Table<GwsLoginByLocationData, keyof GwsLoginByLocationData>
                  columns={locationColumns}
                  pagination={
                    isLoadingAuditLogsSignIns
                      ? undefined
                      : { page: locationPage, perPage: PER_PAGE_LOCATIONS, total: sortedLocations?.length || 0 }
                  }
                  sorter={locationSorter}
                  onChange={(pagination, sorting) => {
                    if (pagination?.page !== undefined) {
                      setLocationPage(pagination?.page);
                    }

                    const isSorterChanging = locationSorter?.key !== sorting?.key || sorting?.order !== sorting?.order;
                    setLocationSorter(sorting);

                    if (isSorterChanging && pagination?.page !== 0) {
                      setLocationPage(0);
                    }
                  }}
                  rowContentCentered
                  className={classes.table}
                  rowKey={(row) => `${row.location}`}
                  data={locationsData}
                  loading={isLoadingAuditLogsSignIns}
                />
              </div>
            </div>
            <div className={classes.section}>{failedLoginsChart}</div>
            <div className={classes.section}>{loginActivityChart}</div>
          </>
        )}
      </div>
    </div>
  );
};
