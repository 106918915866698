import { makeStyles } from '@material-ui/core/styles';
import { Column, Table } from 'components/Table';
import dayjs from 'dayjs';
import { AgentDTO } from 'dtos/agent';
import { ApplicationDocumentsGroupDTO, ApplicationWithAgentDTO } from 'dtos/application';
import { sortBy } from 'lodash';
import { useApplicationDocumentsGroups } from 'queries/useApplications';
import React, { useMemo, useRef } from 'react';
import { generateRefetchIntervalMs } from 'telivy-constants';
import { isAgentAnAdmin } from 'telivy-selectors';

import { DeleteGroupButton } from './DeleteGroupButton';
import { ApplicationDocumentsTable } from './DocumentsTable';
// import { DownloadZippedGroupButton } from './DownloadZippedGroupButton';

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  documentsListContainer: {
    margin: '0 auto',
    maxWidth: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(5),
  },
  row: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  agent: AgentDTO;
}

const generateRowKey = (row: ApplicationDocumentsGroupDTO) => `${row.id}-${row.createdAt}`;

export const DocumentsGroupsTable = ({ application, agent }: Props) => {
  const hasOpenedDefaultGroup = useRef(false);
  const [expandedRows, setExpandedRows] = React.useState<string[]>([]);
  const classes = useStyles();
  const { data, isLoading } = useApplicationDocumentsGroups(application.id, {
    refetchInterval: generateRefetchIntervalMs(),
    onSuccess: (data) => {
      if (data.length > 0 && !hasOpenedDefaultGroup.current) {
        hasOpenedDefaultGroup.current = true;
        const sorted = sortBy(data || [], 'createdAt').reverse();
        setExpandedRows([generateRowKey(sorted[0])]);
      }
    },
  });

  const columns = useMemo(
    (): Column<ApplicationDocumentsGroupDTO>[] => [
      {
        title: 'Report bundle name',
        width: '50%',
        render: (row) => (
          <div className={classes.row}>
            {/*<DownloadZippedGroupButton application={application} group={row} />*/}
            {row.name}
            {isAgentAnAdmin(agent) && <DeleteGroupButton application={application} group={row} />}
          </div>
        ),
      },
      {
        title: 'Created At',
        width: '43%',
        render: (row) => <span>{row.createdAt ? dayjs(row.createdAt).format('MMM DD YYYY hh:mm A') : '-'}</span>,
      },
    ],
    [agent, application, classes.row],
  );

  const handleRowClick = (row: ApplicationDocumentsGroupDTO) => {
    setExpandedRows((prev) => {
      if (prev.includes(generateRowKey(row))) {
        return prev.filter((r) => r !== generateRowKey(row));
      }

      return [...prev, generateRowKey(row)];
    });
  };

  if ((data || []).length === 0) {
    return <></>;
  }

  return (
    <Table<ApplicationDocumentsGroupDTO, undefined>
      columns={columns}
      rowContentCentered
      className={classes.root}
      rowKey={generateRowKey}
      data={sortBy(data || [], 'createdAt').reverse()}
      expandedRowKeys={expandedRows}
      onRowClick={handleRowClick}
      onExpandRowClick={handleRowClick}
      onCollapseExpandAllClick={(type) => {
        if (type === 'expand') {
          setExpandedRows((data || []).map(generateRowKey));
        } else {
          setExpandedRows([]);
        }
      }}
      expandedRowRender={(row) => {
        return (
          <div className={classes.table}>
            <ApplicationDocumentsTable
              documents={row.documents}
              application={application}
              agent={agent}
              isLoading={isLoading}
            />
          </div>
        );
      }}
    />
  );
};
